import type { UserDetailed } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getUserDetails(userId: string): Promise<UserDetailed> {
  const tokens = getTokens()

  return fetch(`${import.meta.env.VITE_API_URL}admin/users/${userId}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const userDetailsQuery = (userId: string) => ({
  queryKey: ['user', userId],
  queryFn: () => getUserDetails(userId),
})

export const getFullNameUser = (user?: UserDetailed) => {
  const fName = `${user?.user?.givenName ?? ''} ${user?.user?.familyName ?? ''}`.trim()

  return fName.length > 0 ? fName : undefined
}
