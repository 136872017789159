import { localStorageKeys } from '../reference'

const STORAGE_KEY = localStorageKeys.cls_plus_admin_live_monitoring

const initialValue = window.localStorage.getItem(STORAGE_KEY)
const liveMonitoredMatchIds = new Set(initialValue ? (JSON.parse(initialValue) as string[]) : [])

export function getLiveMonitoredMatchIds() {
  return liveMonitoredMatchIds
}

export function addLiveMonitoredMatchId(matchId: string) {
  liveMonitoredMatchIds.add(matchId)
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify([...liveMonitoredMatchIds]))
}

export function removeLiveMonitoredMatchId(matchId: string) {
  liveMonitoredMatchIds.delete(matchId)
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify([...liveMonitoredMatchIds]))
}

export function clearLiveMonitoredMatchIds() {
  liveMonitoredMatchIds.clear()
  window.localStorage.removeItem(STORAGE_KEY)
}
