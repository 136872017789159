import { Box, Button, Flex, Heading, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useRevalidator } from 'react-router-dom'

import LiveMonitoredGame from '../../components/live-monitored-game'
import { LiveMonitoringAddMatchModal } from '../../components/live-monitoring-add-match-modal'
import type { MatchLoaderData } from '../../contexts/match.context'
import { matchLoader, MatchProvider } from '../../contexts/match.context'
import { getLiveMonitoredMatchIds, removeLiveMonitoredMatchId } from '../../helpers/liveMonitoringStorage'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const loadedMatches: Map<string, MatchLoaderData> = new Map()
    const liveMonitoredMatchIds = getLiveMonitoredMatchIds()
    for (const id of liveMonitoredMatchIds) {
      const matchData = await matchLoader(queryClient)({ params: { id: id }, request })
      const { advancedModeData, coreModeData, advancedModeBalls, coreModeBalls, matchIds } = matchData
      loadedMatches.set(id, { advancedModeData, coreModeData, advancedModeBalls, coreModeBalls, matchIds })
    }
    return loadedMatches
  }

type MatchPreviewsProps = {
  loadedMatches: Map<string, MatchLoaderData>
}

const MatchPreviews = ({ loadedMatches }: MatchPreviewsProps) => {
  const boxes: JSX.Element[] = []
  const revalidator = useRevalidator()

  for (const [key, value] of loadedMatches) {
    boxes.push(
      <MatchProvider match={value} key={value.coreModeData.id || value.advancedModeData.id}>
        <VStack
          width="100%"
          spacing="2em"
          key={`PreviewMatch_${key}`}
          backgroundColor="primary.600"
          marginTop="1em"
          borderRadius="6px"
        >
          <Box key={`PreviewMatchBody_${key}`} width="100%">
            <Flex
              width="100%"
              backgroundColor="primary.500"
              borderTopLeftRadius="6px"
              borderTopRightRadius="6px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Heading as="h2" size="sm" marginLeft="1em" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {value?.coreModeData?.competitionStage?.competition?.name ||
                  value?.advancedModeData?.competitionStage?.competition?.name ||
                  value?.coreModeData?.title ||
                  value?.advancedModeData?.title}
              </Heading>
              <Button
                variant="solid"
                size="lg"
                borderBottomRightRadius="0"
                borderBottomLeftRadius="0"
                borderTopLeftRadius="0"
                onClick={() => {
                  removeLiveMonitoredMatchId(key)
                  revalidator.revalidate()
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </Flex>
            <LiveMonitoredGame id={key} loadedData={value}></LiveMonitoredGame>
          </Box>
        </VStack>
      </MatchProvider>
    )
  }

  if (loadedMatches.size === 0) {
    boxes.push(
      <Box key={`LiveMonitoredGame_noGames`} paddingTop="1.5em">
        <Text fontSize="1xl" fontStyle="italic" textAlign="center">
          Add a live match to start monitoring...
        </Text>
      </Box>
    )
  }

  return (
    <>
      <SimpleGrid
        columns={(loadedMatches.size === 0 && 1) || { base: 1, xl: 2 }}
        spacing={5}
        width="100%"
        border="1px solid"
        borderColor="primary.500"
        borderTop="none"
        borderBottomLeftRadius="6px"
        borderBottomRightRadius="6px"
        padding={5}
        paddingTop={0}
      >
        {boxes}
      </SimpleGrid>
    </>
  )
}

const LiveMonitor = observer(() => {
  const loadedMatches = useLoaderData() as Map<string, MatchLoaderData>
  const liveMonitoredMatchIds = getLiveMonitoredMatchIds()
  return (
    <>
      <Flex
        flex={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={3}
        bg="primary.600"
        width="100%"
        padding="1em"
        borderTopLeftRadius="6px"
        borderTopRightRadius="6px"
        maxHeight="8em"
      >
        <VStack direction="row" align="flex-start">
          <Text color="white" as="b" fontSize="0.9rem" textTransform="uppercase">
            Live Monitoring
          </Text>
          <Stack direction="row" spacing={3} padding={0} align="flex-end" marginTop={0}>
            <Text color="white" as="b" fontSize="3rem" textTransform="uppercase">
              {liveMonitoredMatchIds.size}
            </Text>
            <Text color="white" as="i" fontSize="0.8rem" paddingBottom="1em">
              Active Match
            </Text>
          </Stack>
        </VStack>

        <Stack direction="row" alignItems="center" spacing={3}>
          <LiveMonitoringAddMatchModal />
        </Stack>
      </Flex>

      <MatchPreviews loadedMatches={loadedMatches} />
    </>
  )
})

export default LiveMonitor
