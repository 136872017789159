import { useToast } from '@chakra-ui/react'
import { upperFirst } from 'lodash'
import { useState } from 'react'

import type { FeedType } from '../types'
import { getTokens } from './auth'

export function useSendFeeds(matchId: string, bypassLookup: boolean = false) {
  const toast = useToast()
  const [isSending, setIsSending] = useState(false)

  const sendFeedToast = (type: FeedType, status: 'success' | 'error') => {
    toast({
      title: `${upperFirst(type)} feed ${status === 'success' ? 'sent successfully' : 'send failed'}`,
      status: status,
      duration: 3000,
    })
  }

  const sendFeed = async (type: FeedType, ballId?: string) => {
    setIsSending(true)
    const tokens = getTokens()
    const gameId = matchId.split('_')[0]
    let url = `${import.meta.env.VITE_API_URL}feed/match/${gameId}`
    if (type === 'match' && bypassLookup) {
      url = `${url}?bypasscache=true`
    }
    if (type === 'allBall') {
      url = `${url}/ball`
    }
    if (type === 'ball' && ballId) {
      url = `${url}/ball/${ballId}`
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { Authorization: `Bearer ${tokens?.accessToken}` },
      })
      if (response) {
        setIsSending(false)
        sendFeedToast(type, response.ok ? 'success' : 'error')
      }
    } catch (error) {
      console.warn('Error sending Feed', error) // eslint-disable-line no-console
      setIsSending(false)
      sendFeedToast(type, 'error')
    }
  }

  return { isSending, sendFeed }
}
