import { Flex, Icon, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CoverageLevels } from '../../reference'

type CoverageLevelIndicatorProps = {
  coverageLevelId?: number | null
  isPreview?: boolean
}

export function CoverageLevelIndicator({ coverageLevelId, isPreview = false }: CoverageLevelIndicatorProps) {
  if (coverageLevelId === undefined || coverageLevelId === null) {
    return <></>
  }

  return (
    <Flex
      alignItems="{{ base: 'flex-start', xl: 'center' }}"
      justifyContent={{ base: 'initial', xl: 'space-between' }}
      borderRadius="6px"
      px={isPreview ? 2 : 4}
      py={3}
      bg={isPreview ? 'primary.500' : 'primary.600'}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Icon as={FontAwesomeIcon} icon={['fas', 'layer-group']} />
        <Text fontSize="xs" fontWeight="bold" color="white">
          {Object.keys(CoverageLevels)[coverageLevelId].replace('_', ' ')}
        </Text>
      </Stack>
    </Flex>
  )
}
