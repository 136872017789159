import { Flex, Tag, Text } from '@chakra-ui/react'
import { isNil } from 'lodash'

import { textCleaner } from '../../helpers/data'
import { getActivePowerPlay, getPowerPlayShortName } from '../../helpers/inning'
import {
  formatMatchDates,
  getActiveInningBattingTeam,
  getActiveMatchBreak,
  getAwayTeam,
  getHomeTeam,
} from '../../helpers/match'
import { BreakTypes, InningsStatus, MatchResultType } from '../../reference'
import type { Match, MatchDls, MatchTeam } from '../../types/match'
import { EntityLink } from '../entity-link'

type TeamProps = {
  team?: MatchTeam
  home?: boolean
  isBatting?: boolean
  maxOvers: number | null
  dls: MatchDls | null
  alignment?: 'left' | 'right' | 'center'
  liveMonitoredMode?: boolean
}

const Team = ({ team, home = false, isBatting, maxOvers, dls, alignment, liveMonitoredMode }: TeamProps) => {
  return (
    <Flex
      flex={1}
      justifyContent="center"
      alignItems="center"
      direction="column"
      backgroundColor={isBatting ? 'green.400' : undefined}
      borderRadius={(() => {
        if (liveMonitoredMode) {
          return '0px'
        }
        return home ? '6px 0px 0px 6px' : '0px 6px 6px 0px'
      })()}
      py={2}
    >
      <Flex
        direction="column"
        justifyContent="center"
        mx="15%"
        w={liveMonitoredMode ? '90%' : '70%'}
        alignItems={(() => {
          switch (alignment) {
            case 'left':
              return 'flex-start'
            case 'right':
              return 'flex-end'
            default:
              return 'center'
          }
        })()}
      >
        <EntityLink entityType="teams" entityId={team?.teamId} idDisplay={liveMonitoredMode ? 'none' : 'popover'}>
          <Text
            textStyle={liveMonitoredMode ? 'h4' : 'h3'}
            fontWeight={liveMonitoredMode ? 'semibold' : undefined}
            fontStyle={liveMonitoredMode ? 'italic' : undefined}
            textAlign="center"
            mb={5}
          >
            {team?.name}
          </Text>
        </EntityLink>

        {team &&
          team.innings.map((inning, idx) => {
            const activePowerPlay = getActivePowerPlay(inning)
            return (
              <Text
                key={inning.inningsMatchOrder}
                as="span"
                textStyle={liveMonitoredMode ? 'h3' : 'h1'}
                textAlign="center"
              >
                {idx > 0 ? ' & ' : ''}
                {inning.progressiveScores.runs}/{inning.progressiveScores.wickets}
                {!isNil(maxOvers) &&
                  maxOvers !== 0 &&
                  parseInt(inning.progressiveScores.oversBowled || '0') !== maxOvers && (
                    <Text ml={2} as="span" textStyle="value" textAlign="center">
                      {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' &&
                        `(${inning.progressiveScores.oversBowled})`}
                      {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' && activePowerPlay && (
                        <Text ml={2} as="span" textStyle="value" textAlign="center">
                          {getPowerPlayShortName(activePowerPlay)}
                        </Text>
                      )}
                      {inning.superOver && (
                        <Text ml={2} as="span" textStyle="value" textAlign="center">
                          s.o
                        </Text>
                      )}
                    </Text>
                  )}
                {(isNil(maxOvers) || maxOvers === 0) && (
                  <Text ml={2} as="span" textStyle="value" textAlign="center">
                    {InningsStatus[inning.inningsStatusId] === 'IN_PROGRESS' &&
                      `(${inning.progressiveScores.oversBowled})`}
                    {inning.closeReasonId === 0 && (
                      <Text ml={2} as="span" textStyle="value" textAlign="center">
                        dec
                      </Text>
                    )}
                  </Text>
                )}
              </Text>
            )
          })}
        {dls?.targetScore && team && team.innings.length > 0 && team.innings[0].inningsMatchOrder === 2 && (
          <Flex flex={1} textAlign="center" alignItems="center" direction="column">
            <Tag size="lg">Target {dls.targetScore}</Tag>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

const MatchInfo = ({ game }: ScoreBoxProps) => {
  const gameBreak = getActiveMatchBreak(game)

  return (
    <Flex flex={1} justifyContent="center" alignItems="center" direction="column" px={5}>
      {gameBreak && (
        <Text textStyle="value" textAlign="center" mb={5}>
          {BreakTypes[gameBreak.matchBreakTypeId ?? 0]}
        </Text>
      )}
      {!isNil(game.matchResultTypeId) && (
        <>
          {!isNil(game.matchStatusId) &&
            !(game.matchStatusId === 0 || game.matchStatusId === 1 || game.matchStatusId === 2) && (
              <Text textStyle="value" textAlign="center">
                {game.description}
              </Text>
            )}
          <Text textStyle="value" textAlign="center" mb={5} fontSize="12px">
            {textCleaner(MatchResultType[game.matchResultTypeId])}
          </Text>
        </>
      )}
      <EntityLink entityType="competitions" entityId={game.competitionStage?.competition?.id} idDisplay="popover">
        <Text textStyle="boldLabel" textAlign="center" mb={5}>
          {game.competitionStage?.competition?.name || game.competitionPool?.name}
        </Text>
      </EntityLink>

      <EntityLink entityType="venues" entityId={game.venue?.id} idDisplay="popover">
        <Text textStyle="boldLabel" textAlign="center">
          {game.venue?.fullName}
        </Text>
      </EntityLink>

      <Text textStyle="boldLabel" textAlign="center">
        {formatMatchDates(game.matchDates)}
      </Text>
    </Flex>
  )
}

type ScoreBoxProps = {
  game: Match
  liveMonitoredMode?: boolean
}

export const ScoreBox = ({ game, liveMonitoredMode = false }: ScoreBoxProps) => {
  const homeTeam = getHomeTeam(game.matchTeams)
  const awayTeam = getAwayTeam(game.matchTeams)

  // only mark current batting team for a match that is In Progress
  const battingTeam = (game.matchStatusId || 0) <= 2 ? getActiveInningBattingTeam(game) : undefined

  return (
    <Flex
      flex={1}
      maxW="container.xl"
      width="100%"
      borderRadius={liveMonitoredMode ? '0px' : '6px'}
      backgroundColor="primary.600"
      minH={liveMonitoredMode ? '6em' : '206px'}
      mt={liveMonitoredMode ? 0 : 6}
    >
      <Team
        team={homeTeam}
        home={true}
        isBatting={homeTeam?.id === battingTeam?.id}
        maxOvers={game.matchConfigs?.maxOvers ?? null}
        dls={game.matchDls}
        alignment={liveMonitoredMode ? 'left' : 'center'}
        liveMonitoredMode={liveMonitoredMode}
      />
      {!liveMonitoredMode ? <MatchInfo game={game} /> : null}
      <Team
        team={awayTeam}
        isBatting={awayTeam?.id === battingTeam?.id}
        maxOvers={game.matchConfigs?.maxOvers ?? null}
        dls={game.matchDls}
        alignment={liveMonitoredMode ? 'right' : 'center'}
        liveMonitoredMode={liveMonitoredMode}
      />
    </Flex>
  )
}
