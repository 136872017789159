import { Box, Flex, Stack, Text } from '@chakra-ui/react'

import type { Ball } from '../../types/ball'
import type { Inning, Match, MatchDls } from '../../types/match'
import { InfoSection } from '../info-section'
import { Batting } from '../scorebook-panels/batting'
import { Bowling } from '../scorebook-panels/bowling'
import { Extras } from '../scorebook-panels/extras'
import { Partnerships } from '../scorebook-panels/partnerships'
import { Squads } from '../squads'

type ScorebookProps = {
  game: Match
  selectedInning?: Inning
  setModalAndBall?: (data: Ball | MatchDls | null) => void
  condensed: boolean
}

const ScorebookInnings = ({ game, selectedInning, condensed }: ScorebookProps) => {
  if (!selectedInning) return null

  return (
    <Stack spacing={6}>
      <Box borderColor="primary.600" borderLeftWidth="1px" borderRightWidth="1px" borderRadius="6px" overflow="hidden">
        <Batting inning={selectedInning} condensed={condensed} />
        <Extras inning={selectedInning} condensed={condensed} />
        <Flex align="center" justify="space-between" p={2} bg="primary.600">
          <Text fontSize="xl" fontWeight="bold">
            TOTAL {selectedInning.progressiveScores.runs}/{selectedInning.progressiveScores.wickets} (
            {selectedInning.progressiveScores.oversBowled} Overs)
          </Text>
          <Text px={2} fontSize="sm" fontWeight="bold">
            {selectedInning?.battingReviewsRemaining ?? 0} of {game.matchConfigs?.maxBattingReviewsPerInnings ?? 0}{' '}
            reviews remaining
          </Text>
        </Flex>
      </Box>
      <Box borderColor="primary.600" borderLeftWidth="1px" borderRightWidth="1px" borderRadius="6px" overflow="hidden">
        <Bowling
          inning={selectedInning}
          game={game}
          ballsPerOver={game.matchConfigs?.ballsPerOver || 6}
          condensed={condensed}
        />
      </Box>
      <Box>
        <Partnerships inning={selectedInning} condensed={condensed} />
      </Box>
    </Stack>
  )
}

export const Scorebook = ({ game, selectedInning, setModalAndBall, condensed }: ScorebookProps) => {
  return (
    <Stack spacing={8}>
      {selectedInning && <ScorebookInnings game={game} selectedInning={selectedInning} condensed={condensed} />}
      <Squads game={game} condensed={condensed} />
      <InfoSection game={game} setModalAndBall={setModalAndBall} />
    </Stack>
  )
}
