import { Button, Flex, Heading, Stack } from '@chakra-ui/react'

import { ScoringModeProvider, useScoringMode } from '../../contexts/scoring-mode.context'
import { getTokens } from '../../helpers/auth'
import type { ClspMode } from '../../types'
import { ModeHeading } from '../mode-heading'

const tokens = getTokens()

type DownloadsPanelProps = {
  gameId: string
  compId: string | null | undefined
  showAdvancedData: boolean
  isPostMatchCoverageLevel: boolean
}

type DownloadButtonsProps = Omit<DownloadsPanelProps, 'isPostMatchCoverageLevel'>

const downloadMatchFile = async (compId: string, mode: ClspMode) => {
  const url = `${import.meta.env.VITE_API_URL}stats/matches/${compId}?mode=${mode}`
  const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
  if (response && response.ok) {
    const blob = await response.blob()
    if (blob) {
      const urlA = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = urlA
      a.download = `comp_${compId}.csv`
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}

const playerInningsFile = async (gameId: string, mode: ClspMode) => {
  const url = `${import.meta.env.VITE_API_URL}stats/playerinnings/${gameId}?mode=${mode}`
  const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
  if (response && response.ok) {
    const blob = await response.blob()
    if (blob) {
      const urlA = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = urlA
      a.download = `player_innings_${gameId}.csv`
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}

const teamInningsFile = async (gameId: string, mode: ClspMode) => {
  const url = `${import.meta.env.VITE_API_URL}stats/teaminnings/${gameId}?mode=${mode}`
  const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
  if (response && response.ok) {
    const blob = await response.blob()
    if (blob) {
      const urlA = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = urlA
      a.download = `team_innings_${gameId}.csv`
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}

const deliveriesFile = async (gameId: string, mode: ClspMode) => {
  const url = `${import.meta.env.VITE_API_URL}stats/matchballs/${gameId}?mode=${mode}`
  const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
  if (response && response.ok) {
    const blob = await response.blob()
    if (blob) {
      const urlA = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = urlA
      a.download = `deliveries_${gameId}.csv`
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}

const matchWithEnums = async (gameId: string, mode: ClspMode) => {
  const url = `${import.meta.env.VITE_API_URL}match/${gameId}?enums=true&mode=${mode}`
  const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
  if (response && response.ok) {
    const blob = await response.blob()
    if (blob) {
      const urlA = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = urlA
      a.download = `match_with_enums_${gameId}.json`
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}

const ballsWithEnums = async (gameId: string, mode: ClspMode) => {
  const url = `${import.meta.env.VITE_API_URL}balls/${gameId}?enums=true&mode=${mode}`
  const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
  if (response && response.ok) {
    const blob = await response.blob()
    if (blob) {
      const urlA = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = urlA
      a.download = `ball_with_enums_${gameId}.json`
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}

const DownloadButtons = ({ gameId, compId, showAdvancedData }: DownloadButtonsProps) => {
  const mode = useScoringMode()
  return (
    <Flex direction="column">
      {showAdvancedData && <ModeHeading />}
      <Stack spacing={4}>
        <Stack spacing={3} p={4} borderRadius="6px" border="1px solid" borderColor="primary.600">
          <Heading as="h4" fontSize="lg">
            CSV
          </Heading>
          <Stack direction="row" spacing={2}>
            {compId && <Button onClick={() => downloadMatchFile(compId, mode)}>Match File</Button>}
            <Button onClick={() => playerInningsFile(gameId, mode)}>Player Innings</Button>
            <Button onClick={() => teamInningsFile(gameId, mode)}>Team Innings</Button>
            <Button onClick={() => deliveriesFile(gameId, mode)}>Deliveries</Button>
          </Stack>
        </Stack>
        <Stack spacing={3} p={4} borderRadius="6px" border="1px solid" borderColor="primary.600">
          <Heading as="h4" fontSize="lg">
            JSON
          </Heading>
          <Stack direction="row" spacing={2}>
            <Button onClick={() => matchWithEnums(gameId, mode)}>Match with Enums</Button>
            <Button onClick={() => ballsWithEnums(gameId, mode)}>Balls with Enums</Button>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  )
}

export const DownloadsPanel = ({ gameId, compId, showAdvancedData, isPostMatchCoverageLevel }: DownloadsPanelProps) => {
  return (
    <Stack direction="row" spacing={6}>
      {!isPostMatchCoverageLevel && (
        <ScoringModeProvider mode="core">
          <DownloadButtons gameId={gameId} compId={compId} showAdvancedData={showAdvancedData} />
        </ScoringModeProvider>
      )}
      {showAdvancedData && (
        <ScoringModeProvider mode="advanced">
          <DownloadButtons gameId={gameId} compId={compId} showAdvancedData={showAdvancedData} />
        </ScoringModeProvider>
      )}
    </Stack>
  )
}
