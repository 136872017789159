import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Tooltip,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { SyntheticEvent } from 'react'
import { useReducer, useRef, useState } from 'react'
import type { LocationOptions, VirtuosoHandle } from 'react-virtuoso'
import { Virtuoso as List } from 'react-virtuoso'

import { ScoringModeProvider } from '../../contexts/scoring-mode.context'
import { getInningsBattingTeamShortName } from '../../helpers/inning'
import { getAllInningsInOrder } from '../../helpers/match'
import { useSendFeeds } from '../../helpers/useSendFeeds'
import type { Ball, ConfirmedBallsByOver } from '../../types/ball'
import type { Inning, Match, MatchDls } from '../../types/match'
import { BallOver } from '../ball-over'
import { ModeHeading } from '../mode-heading'

type BallPanelProps = {
  game: Match
  activeTab: number
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  activeInning: Inning | undefined
  ballsCore: ConfirmedBallsByOver | undefined
  ballKeysCore: number[]
  ballsAdvanced?: ConfirmedBallsByOver
  ballKeysAdvanced?: number[]
  setModalAndBall: (data: Ball | MatchDls | null) => void
  bypassLookup?: boolean
  showAdvancedData: boolean
  isPostMatchCoverageLevel: boolean
}

export const BallPanel = ({
  game,
  activeTab,
  setActiveTab,
  activeInning,
  ballsCore,
  ballKeysCore,
  ballsAdvanced,
  ballKeysAdvanced,
  setModalAndBall,
  bypassLookup,
  showAdvancedData,
  isPostMatchCoverageLevel,
}: BallPanelProps) => {
  const listCoreRef = useRef<VirtuosoHandle>(null)
  const listAdvancedRef = useRef<VirtuosoHandle>(null)
  const [align] = useState<LocationOptions['align']>('start')
  const [behavior] = useState<LocationOptions['behavior']>('auto')
  const { sendFeed, isSending } = useSendFeeds(game.id, bypassLookup)

  const handleSubmit = (data: ScrollToOverFormState) => {
    if (data?.over) {
      const indexCore = ballKeysCore.length - data?.over
      const indexAdvanced = (ballKeysAdvanced?.length || 0) - data?.over
      if (listCoreRef?.current) {
        listCoreRef.current.scrollToIndex({
          index: indexCore,
          align,
          behavior,
        })
      }
      if (listAdvancedRef?.current) {
        listAdvancedRef.current.scrollToIndex({
          index: indexAdvanced,
          align,
          behavior,
        })
      }
    }
  }

  if (!activeInning) return null

  const RowCore = (index: number) => {
    return (
      <BallOver
        key={`core_inning_${activeInning.inningsMatchOrder}_over_${index.toString()}`}
        inningMatchOrder={activeInning.inningsMatchOrder}
        overNumber={ballKeysCore[index]}
        over={ballsCore ? ballsCore[ballKeysCore[index]] : []}
        setModalAndBall={setModalAndBall}
        activeInning={activeInning}
        sendFeed={sendFeed}
        isSending={isSending}
        condensed={showAdvancedData && ballKeysAdvanced && ballsAdvanced ? true : false}
      />
    )
  }

  const RowAdvanced = (index: number) => {
    if (!ballKeysAdvanced || !ballsAdvanced) return
    return (
      <BallOver
        key={`advanced_inning_${activeInning.inningsMatchOrder}_over_${index.toString()}`}
        inningMatchOrder={activeInning.inningsMatchOrder}
        overNumber={ballKeysAdvanced[index]}
        over={ballsAdvanced[ballKeysAdvanced[index]]}
        setModalAndBall={setModalAndBall}
        activeInning={activeInning}
        sendFeed={sendFeed}
        isSending={isSending}
        condensed={true}
        isAdvanced={true}
      />
    )
  }

  return (
    <>
      <Flex w="100%">
        <Flex flex={1} />
        <Flex flex={1} align="center" justifyContent="center">
          <ButtonGroup size="sm" isAttached>
            {getAllInningsInOrder(game).map(inning => {
              return (
                <Button
                  key={inning.inningsMatchOrder}
                  mr="-px"
                  fontSize="16px"
                  paddingX="30px"
                  isActive={inning.inningsMatchOrder === activeTab}
                  onClick={() => setActiveTab(inning.inningsMatchOrder)}
                  _active={{
                    bg: 'green.400',
                    color: 'white',
                  }}
                >
                  {getInningsBattingTeamShortName(inning, game.matchTeams)} {inning.progressiveScores.runs}/
                  {inning.progressiveScores.wickets}
                </Button>
              )
            })}
          </ButtonGroup>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <ScrollToOverForm
            ballKeys={isPostMatchCoverageLevel && ballKeysAdvanced ? ballKeysAdvanced : ballKeysCore}
            onSubmit={handleSubmit}
          />
        </Flex>
      </Flex>
      {activeInning && (
        <Stack direction="row" spacing={5} flex={1} mt={4}>
          {!isPostMatchCoverageLevel && ballsCore && ballKeysCore.length > 0 && (
            <ScoringModeProvider mode="core">
              <Flex flex={1} direction="column" height="800px">
                {showAdvancedData && <ModeHeading />}
                <List ref={listCoreRef} totalCount={ballKeysCore.length} itemContent={RowCore} />
              </Flex>
            </ScoringModeProvider>
          )}
          {showAdvancedData && ballsAdvanced && ballKeysAdvanced && ballKeysAdvanced.length > 0 && (
            <ScoringModeProvider mode="advanced">
              <Flex flex={1} direction="column" height="800px">
                {!isPostMatchCoverageLevel && <ModeHeading />}
                <List ref={listAdvancedRef} totalCount={ballKeysAdvanced.length} itemContent={RowAdvanced} />
              </Flex>
            </ScoringModeProvider>
          )}
        </Stack>
      )}
    </>
  )
}

type ScrollToOverFormState = {
  over: number
  reset?: boolean
}

type ScrollToOverFormAction = {
  reset?: boolean
  name?: string
  value?: string
}

const scrollToOverFormReducer = (state: ScrollToOverFormState, event: ScrollToOverFormAction) => {
  if (event.reset) {
    return {
      over: 0,
    }
  }
  if (event.name) {
    return {
      ...state,
      [event.name]: event.value,
    }
  }
  return { ...state }
}

type ScrollToOverFormProps = {
  ballKeys: number[]
  onSubmit: (data: ScrollToOverFormState) => void
}

const ScrollToOverForm = ({ ballKeys, onSubmit }: ScrollToOverFormProps) => {
  const [formData, setFormData] = useReducer(scrollToOverFormReducer, { over: 0 })
  const [submitting, setSubmitting] = useState(false)
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    setSubmitting(true)
    setTimeout(() => {
      setSubmitting(false)
      onSubmit(formData)
      setFormData({ reset: true })
    }, 1000)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Flex justifyContent="flex-end">
        <FormControl maxW="150px" id="over">
          <Tooltip hasArrow label="Scroll to over">
            <NumberInput
              id="over"
              onChange={val => {
                setFormData({
                  name: 'over',
                  value: val,
                })
              }}
              allowMouseWheel
              size="sm"
              defaultValue={0}
              min={0}
              max={ballKeys.length}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Tooltip>
        </FormControl>
        <Button ml="1" isLoading={submitting} size="sm" type="submit">
          <Icon as={FontAwesomeIcon} icon={['fas', 'search']} />
        </Button>
      </Flex>
    </form>
  )
}
