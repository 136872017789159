import { Button, Flex, Heading, Icon, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { getFullNameUser, userDetailsQuery } from '../../../api/get-user-details'
import { DetailValue } from '../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(userDetailsQuery(params.userId || ''))
  }

export default function UserDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { data: userDetails } = useQuery({
    ...userDetailsQuery(params.userId || ''),
    initialData,
  })

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {getFullNameUser(userDetails)}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <Button
            as={RouterLink}
            to={`/users/${userDetails.user?.id}/edit`}
            variant="primary"
            minW={32}
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
          >
            Edit
          </Button>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="ID" value={userDetails.user?.id} />
        <DetailValue label="Given name" value={userDetails.user?.givenName} />
        <DetailValue label="Family name" value={userDetails.user?.familyName} />
        <DetailValue label="Email" value={userDetails.user?.email} />
        <DetailValue
          label="Enabled"
          value={
            <FontAwesomeIcon
              icon={['fas', userDetails.user?.enabled ? 'check-circle' : 'circle-xmark']}
              size="sm"
              style={{ marginRight: '8px', fontSize: '12px' }}
            />
          }
        />
        <DetailValue label="Status" value={userDetails.user?.status} />
        <DetailValue
          label="Groups"
          value={userDetails.groups?.map(group => (
            <Text key={group}>{group}</Text>
          ))}
        />
      </Stack>
    </>
  )
}
