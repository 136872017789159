import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendarDays,
  faCircleDot,
  faCricketBatBall,
  faDownToBracket,
  faFilterList,
  faHouse,
  faLocationDot,
  faMerge,
  faPenToSquare,
  faPeopleRoof,
  faPersonRunning,
  faPersonToDoor,
  faRotate,
  faShieldHalved,
  faTrashXmark,
  faTriangleExclamation,
  faTrophy,
  faUpFromBracket,
  faUserGroup,
} from '@fortawesome/pro-duotone-svg-icons'
import { faCricket } from '@fortawesome/pro-light-svg-icons'
import {
  faBars,
  faCloudShowers,
  faExpandArrowsAlt,
  faRulerTriangle,
  faSeedling,
  faSunCloud,
  faTachometerAltFast,
  faTemperatureHot,
  faTimes,
  faTint,
  faWind,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCheck,
  faCheckCircle,
  faCircleXmark,
  faEllipsis,
  faExclamationCircle,
  faFaceConfused,
  faLayerGroup,
  faPlus,
  faRotateLeft,
  faSearch,
  faXmarkLarge,
} from '@fortawesome/pro-solid-svg-icons'

export const IconLibrary = () => {
  library.add(
    faCircleDot,
    faBars,
    faCalendarDays,
    faCheck,
    faCheckCircle,
    faCircleXmark,
    faCloudShowers,
    faCricket,
    faCricketBatBall,
    faDownToBracket,
    faEllipsis,
    faExclamationCircle,
    faExpandArrowsAlt,
    faFaceConfused,
    faFilterList,
    faHouse,
    faLayerGroup,
    faLocationDot,
    faMerge,
    faPenToSquare,
    faPeopleRoof,
    faPersonRunning,
    faPersonToDoor,
    faPlus,
    faRotate,
    faRulerTriangle,
    faSearch,
    faSeedling,
    faShieldHalved,
    faSunCloud,
    faTachometerAltFast,
    faTemperatureHot,
    faTimes,
    faTint,
    faRotateLeft,
    faTrashXmark,
    faTriangleExclamation,
    faTrophy,
    faUpFromBracket,
    faUserGroup,
    faWind,
    faXmarkLarge
  )
}
