import { getTokens } from '../../helpers/auth'
import type { ClspMode } from '../../types'
import type { Match } from '../../types/match'

type GetMatchCentreMatchParams = {
  id: string
  mode: ClspMode | 'postMatch'
  bypassLookup: boolean
}

function getMatchCentreMatch(params: GetMatchCentreMatchParams): Promise<Match> {
  const tokens = getTokens()
  const { id, mode, bypassLookup } = params

  return fetch(
    `${import.meta.env.VITE_API_URL}match/${id}?mode=${mode}${bypassLookup === true ? '&bypassLookup=true' : ''}`,
    {
      headers: { Authorization: `Bearer ${tokens?.accessToken}` },
    }
  )
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const matchCentreMatchQuery = (params: GetMatchCentreMatchParams) => ({
  queryKey: ['match-centre-match', params],
  queryFn: () => getMatchCentreMatch(params),
})
