import { Flex, Text } from '@chakra-ui/react'
import { capitalize } from 'lodash'

import { useScoringMode } from '../../contexts/scoring-mode.context'

export const ModeHeading = () => {
  const mode = useScoringMode()
  return (
    <Flex w="100%" flexDirection="row" mb={3}>
      <Text as="h2" w="100%" px={2} fontWeight="bold" fontSize="xl" textAlign="center">
        {`${capitalize(mode)} mode`}
      </Text>
    </Flex>
  )
}
