import createHash from 'create-hash'
import type { JwtPayload } from 'jwt-decode'
import jwt_decode from 'jwt-decode'
import randomBytes from 'randombytes'

import { localStorageKeys } from '../reference'
import { getNavGroups } from './nav'

type UserProfile = JwtPayload & {
  at_hash?: string
  auth_time?: number
  'cognito:groups'?: string[]
  'cognito:username'?: string
  email?: string
  email_verified?: boolean
  event_id?: string
  family_name?: string
  given_name?: string
  token_use?: string
}

const SCOPE = 'email+openid+profile+aws.cognito.signin.user.admin'

const accessToken = window.localStorage.getItem(localStorageKeys.cls_plus_admin_accessToken)
const idToken = window.localStorage.getItem(localStorageKeys.cls_plus_admin_idToken)
const refreshToken = window.localStorage.getItem(localStorageKeys.cls_plus_admin_refreshToken)

let userProfile = idToken ? jwt_decode<UserProfile>(idToken) : null

let tokens =
  accessToken === null
    ? null
    : {
        accessToken,
        idToken,
        refreshToken,
      }

const base64URLEncode = (str: Buffer) => {
  return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

const sha256 = (buffer: Buffer | string) => {
  return createHash('sha256').update(buffer).digest()
}

let storedVerifier = window.localStorage.getItem('cls_plus_admin_verifier')
if (storedVerifier === null) {
  const newVerifier = base64URLEncode(randomBytes(32))
  window.localStorage.setItem('cls_plus_admin_verifier', newVerifier)
  storedVerifier = newVerifier
}
const verifier = storedVerifier
const challenge = base64URLEncode(sha256(storedVerifier))
const baseUrl = import.meta.env.VITE_AUTH_BASE_URL
const clientId = import.meta.env.VITE_AUTH_CLIENT_ID
const redirectUri = import.meta.env.VITE_AUTH_REDIRECT_URI
const tokenUrl = `${baseUrl}/oauth2/token`

// eslint-disable-next-line max-len
export const loginUrl = `${baseUrl}/login?scope=${SCOPE}&response_type=code&client_id=${clientId}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUri}`

const refreshTokens = async (refreshToken: string) => {
  const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`
  const response = await fetch(tokenUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body,
  })
  if (!response.ok) {
    // basically if the refresh did not work, we should log them out
    logout()
    return false
  }
  const { access_token, id_token } = await response.json()
  window.localStorage.setItem(localStorageKeys.cls_plus_admin_accessToken, access_token)
  window.localStorage.setItem(localStorageKeys.cls_plus_admin_idToken, id_token)
  window.localStorage.setItem(localStorageKeys.cls_plus_admin_refreshToken, refreshToken)
  tokens = {
    accessToken: access_token,
    idToken: id_token,
    refreshToken,
  }
  userProfile = jwt_decode<UserProfile>(id_token)
  return tokens
}

export const login = async (code: string) => {
  // eslint-disable-next-line max-len
  const body = `grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&code=${code}&redirect_uri=${redirectUri}`
  const response = await fetch(tokenUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body,
  })
  if (!response.ok) {
    throw Error()
  }
  const { access_token, id_token, refresh_token } = await response.json()
  window.localStorage.setItem(localStorageKeys.cls_plus_admin_accessToken, access_token)
  window.localStorage.setItem(localStorageKeys.cls_plus_admin_idToken, id_token)
  window.localStorage.setItem(localStorageKeys.cls_plus_admin_refreshToken, refresh_token)
  tokens = {
    accessToken: access_token,
    idToken: id_token,
    refreshToken: refresh_token,
  }
  userProfile = jwt_decode(id_token)
}

export const logout = async () => {
  window.localStorage.removeItem(localStorageKeys.cls_plus_admin_accessToken)
  window.localStorage.removeItem(localStorageKeys.cls_plus_admin_idToken)
  window.localStorage.removeItem(localStorageKeys.cls_plus_admin_refreshToken)
  window.localStorage.removeItem(localStorageKeys.cls_plus_admin_live_monitoring)
  tokens = null
  userProfile = null
}

export const checkTokens = async (accessToken: string | null, refreshToken: string | null) => {
  if (!accessToken || !refreshToken) {
    return false
  }

  // Bypass login checks when e2e tests are running
  if (process.env.PLAYWRIGHT_ACTIVE) return true

  const decodedToken = jwt_decode<UserProfile>(accessToken)
  // if the expiry of this token is not beyond 12 hours from now, then we will refresh the token
  const targetTime = Math.floor(Date.now() / 1000) + 43200 // now plus 12 hours
  if (decodedToken && decodedToken.exp && refreshToken) {
    if (decodedToken.exp > targetTime) {
      // eslint-disable-next-line
      console.log('token is valid')
      return true
    } else {
      // eslint-disable-next-line
      console.log('token is not valid')
      // refresh the token
      const result = await refreshTokens(refreshToken)
      return !!result
    }
  } else {
    // we have no token - log out?
    logout()
    return false
  }
}

export const getTokens = () => tokens
export const getUserProfile = () => userProfile

export type MCRequiredLevel = 'locked' | 'clsp-monitoring-users' | 'cpa-admins' | 'clsp-superadmin'
export enum MCRequiredLevelEnum {
  locked = 0,
  'clsp-monitoring-users' = 1,
  'cpa-admins' = 2,
  'clsp-superadmin' = 3,
}

const checkAuthLevel = (): MCRequiredLevelEnum => {
  const cognitoGroups = getUserProfile()?.['cognito:groups'] as Array<MCRequiredLevel> | undefined

  const acquiredLevels: Array<MCRequiredLevelEnum> = []

  for (const key of Object.keys(MCRequiredLevelEnum) as Array<MCRequiredLevel>) {
    if (cognitoGroups?.includes(key)) {
      acquiredLevels.push(MCRequiredLevelEnum[key])
    }
  }

  return acquiredLevels.sort()[acquiredLevels.length - 1] ?? MCRequiredLevelEnum.locked
}

export const CurrentAccessLevel = () => {
  return window.localStorage.getItem('AccessOverrideLevel')
    ? (Number(window.localStorage.getItem('AccessOverrideLevel')) as MCRequiredLevelEnum)
    : checkAuthLevel()
}

export const HasMCAccess = ({ children, minLevel }: { children: JSX.Element; minLevel: MCRequiredLevelEnum }) => {
  if (CurrentAccessLevel() >= minLevel) {
    return children
  }

  return null
}

export const getNavGroupAccess = () => {
  const navGroupMap = new Map<string, MCRequiredLevelEnum>()

  for (const nvGroup of getNavGroups()) {
    for (const nvItem of nvGroup.items) {
      navGroupMap.set(nvItem.href, nvItem.requiredAccess)
    }
  }

  return navGroupMap
}
