import { Text } from '@chakra-ui/react'
import type {
  CompetitionDetailed,
  CompetitionPoolDetailed,
  CompetitionStageDetailed,
  CompetitionTeamDetailed,
  MatchDetailed,
  OrganisationDetailed,
  PersonDetailed,
  TeamDetailed,
  UserDetailed,
  VenueDetailed,
} from '@clsplus/api-types/api-admin'
import type { GetMergeRequestsResponse } from '@clsplus/api-types/entity-merge'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { lazy, Suspense, useEffect, useState } from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'

import { getFullNameUser } from './api/get-user-details'
import { BreadcrumbLink } from './components/breadcrumb'
import { Layout } from './components/layout'
import { checkTokens, getTokens, login } from './helpers/auth'
import { getActiveInning, getAwayTeam, getHomeTeam } from './helpers/match'
import { Authenticating } from './screens/authenticating'
import { loader as competitionLoader } from './screens/competitions/details'
import CompetitionEdit, { loader as competitionEditLoader } from './screens/competitions/edit'
import { loader as competitionsLoader } from './screens/competitions/list'
import { loader as competitionStageLoader } from './screens/competitions/stages/details'
import { loader as competitionStageEditLoader } from './screens/competitions/stages/edit'
import { loader as competitionStagePoolLoader } from './screens/competitions/stages/pools/details'
import CompetitionPoolEdit, { loader as competitionPoolEditLoader } from './screens/competitions/stages/pools/edit'
import { loader as competitionTeamLoader } from './screens/competitions/teams/details'
import CompetitionTeamEdit, { loader as competitionTeamEditLoader } from './screens/competitions/teams/edit'
import { loader as liveMonitoringLoader } from './screens/live-monitoring'
import { Login } from './screens/login'
import { loader as matchCentreMatchLoader } from './screens/match'
import { loader as matchCentreMatchesLoader } from './screens/match-centre'
import { loader as matchDetailsLoader } from './screens/matches/details'
import { loader as matchEditLoader } from './screens/matches/edit'
import { loader as matchesLoader } from './screens/matches/list'
import { loader as mergeRequestLoader } from './screens/merge-requests/details'
import { loader as mergeRequestsLoader } from './screens/merge-requests/list'
import CreateOrganisation, { loader as createOrganisationLoader } from './screens/organisations/create'
import { loader as organisationLoader } from './screens/organisations/details'
import { loader as organisationEditLoader } from './screens/organisations/edit'
import { loader as organisationsLoader } from './screens/organisations/list'
import { loader as personLoader } from './screens/people/details'
import { loader as personEditLoader } from './screens/people/edit'
import { loader as peopleLoader } from './screens/people/list'
import { loader as teamLoader } from './screens/teams/details'
import { loader as teamEditLoader } from './screens/teams/edit'
import { loader as teamsLoader } from './screens/teams/list'
import CreateUser from './screens/users/create'
import UserDetails, { loader as userLoader } from './screens/users/details'
import { loader as userEditLoader } from './screens/users/edit'
import Users, { loader as usersLoader } from './screens/users/list'
import { loader as venueLoader } from './screens/venues/details'
import { loader as venueEditLoader } from './screens/venues/edit'
import { loader as venuesLoader } from './screens/venues/list'
import type { Match } from './types/match'
import { ErrorBoundary } from './ui/error-boundary'
import { Loading } from './ui/loading'

const Competitions = lazy(() => import('./screens/competitions/list'))
const CompetitionDetails = lazy(() => import('./screens/competitions/details'))
const CompetitionTeamDetails = lazy(() => import('./screens/competitions/teams/details'))
const StageDetails = lazy(() => import('./screens/competitions/stages/details'))
const CompetitionStageEdit = lazy(() => import('./screens/competitions/stages/edit'))
const StagePoolDetails = lazy(() => import('./screens/competitions/stages/pools/details'))
const CompetitionSync = lazy(() => import('./screens/competition-sync'))
const CompetitionFeeds = lazy(() => import('./screens/competition-feeds'))
const Game = lazy(() => import('./screens/match'))
const MatchCentre = lazy(() => import('./screens/match-centre'))
const LiveMonitoring = lazy(() => import('./screens/live-monitoring'))
const MatchDetails = lazy(() => import('./screens/matches/details'))
const MatchEdit = lazy(() => import('./screens/matches/edit'))
const Matches = lazy(() => import('./screens/matches/list'))
const MergeRequestCreate = lazy(() => import('./screens/merge-requests/create'))
const MergeRequestDetails = lazy(() => import('./screens/merge-requests/details'))
const MergeRequests = lazy(() => import('./screens/merge-requests/list'))
const Organisations = lazy(() => import('./screens/organisations/list'))
const OrganisationDetails = lazy(() => import('./screens/organisations/details'))
const OrganisationEdit = lazy(() => import('./screens/organisations/edit'))
const PersonDetails = lazy(() => import('./screens/people/details'))
const PersonEdit = lazy(() => import('./screens/people/edit'))
const People = lazy(() => import('./screens/people/list'))
const Subscriptions = lazy(() => import('./screens/subscriptions'))
const Teams = lazy(() => import('./screens/teams/list'))
const TeamDetails = lazy(() => import('./screens/teams/details'))
const TeamEdit = lazy(() => import('./screens/teams/edit'))
const Venues = lazy(() => import('./screens/venues/list'))
const VenueDetails = lazy(() => import('./screens/venues/details'))
const VenueEdit = lazy(() => import('./screens/venues/edit'))
const UserEdit = lazy(() => import('./screens/users/edit'))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: query => query.state.status !== 'error',
      staleTime: 1000,
    },
  },
})

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />} errorElement={<ErrorBoundary h="100%" p={6} />}>
      <Route
        path="/live-monitoring"
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/live-monitoring" to="/live-monitoring">
              Live Monitoring
            </BreadcrumbLink>
          ),
          title: () => 'Live Monitoring',
        }}
        errorElement={<ErrorBoundary />}
      >
        <Route
          id="match-live-monitoring"
          index
          loader={liveMonitoringLoader(queryClient)}
          element={
            <Suspense fallback={<Loading />}>
              <LiveMonitoring />
            </Suspense>
          }
          handle={{ title: 'Live Monitoring' }}
          errorElement={<ErrorBoundary />}
        />
      </Route>
      <Route
        path="/match-centre"
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/match-centre" to="/match-centre">
              Match centre
            </BreadcrumbLink>
          ),
          title: () => 'Match centre',
        }}
        errorElement={<ErrorBoundary />}
      >
        <Route
          index
          loader={matchCentreMatchesLoader(queryClient)}
          element={
            <Suspense fallback={<Loading />}>
              <MatchCentre />
            </Suspense>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          id="match"
          path=":id"
          element={
            <Suspense fallback={<Loading />}>
              <Game />
            </Suspense>
          }
          loader={matchCentreMatchLoader(queryClient)}
          handle={{
            crumb: ({ coreModeData }: { coreModeData: Match }) => {
              return (
                <BreadcrumbLink key={coreModeData.id || 'undefined_match'} to={`/match-centre/${coreModeData.id}`}>
                  {getHomeTeam(coreModeData.matchTeams)?.name || 'Unknown team'} vs{' '}
                  {getAwayTeam(coreModeData.matchTeams)?.name || 'Unknown team'}
                </BreadcrumbLink>
              )
            },
            title: ({ coreModeData }: { coreModeData: Match }) => {
              const activeInning = getActiveInning(coreModeData)

              if (activeInning) {
                const battingTeam = coreModeData.matchTeams?.find(team => team.id === activeInning.battingTeamId)
                return `${battingTeam?.shortName || 'Unknown team'}
                ${activeInning.progressiveScores.runs}/${activeInning.progressiveScores.wickets}
                  (${activeInning.progressiveScores.oversBowled})`
              }

              return `${getHomeTeam(coreModeData.matchTeams)?.name} vs ${getAwayTeam(coreModeData.matchTeams)?.name}`
            },
          }}
          errorElement={<ErrorBoundary />}
        />
      </Route>
      <Route
        path="competition-feeds"
        element={
          <Suspense fallback={<Loading />}>
            <CompetitionFeeds />
          </Suspense>
        }
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/competition-feeds" to="/competition-feeds">
              Competition feeds
            </BreadcrumbLink>
          ),
          title: () => 'Competition feeds',
        }}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="subscriptions"
        element={
          <Suspense fallback={<Loading />}>
            <Subscriptions />
          </Suspense>
        }
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/subscriptions" to="/subscriptions">
              Subscriptions
            </BreadcrumbLink>
          ),
          title: () => 'Subscriptions',
        }}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="competition-sync"
        element={
          <Suspense fallback={<Loading />}>
            <CompetitionSync />
          </Suspense>
        }
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/competition-sync" to="/competition-sync">
              Competition sync
            </BreadcrumbLink>
          ),
          title: () => 'Competition sync',
        }}
        errorElement={<ErrorBoundary />}
      />
      {import.meta.env.VITE_ENTITY_MERGE_ENABLED === 'true' && (
        <Route
          path="merge-requests"
          handle={{
            crumb: () => (
              <BreadcrumbLink key="/merge-requests" to="/merge-requests">
                Merge requests
              </BreadcrumbLink>
            ),
            title: () => 'Merge requests',
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            loader={mergeRequestsLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <MergeRequests />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="create"
            element={
              <Suspense fallback={<Loading />}>
                <MergeRequestCreate />
              </Suspense>
            }
            handle={{
              crumb: () => (
                <Text key="new-merge-request" fontSize="sm" fontWeight="bold">
                  New
                </Text>
              ),
              title: () => 'New merge request',
            }}
            errorElement={<ErrorBoundary />}
          />
          <Route
            path=":mergeRequestId"
            loader={mergeRequestLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <MergeRequestDetails />
              </Suspense>
            }
            handle={{
              // TODO: Replace usage of GetMergeRequestsResponse[number] with MergeRequestBase after types are updated
              crumb: (mergeRequests: GetMergeRequestsResponse[number][]) =>
                mergeRequests[0] ? (
                  <BreadcrumbLink key={mergeRequests[0].id} to={`/merge-requests/${mergeRequests[0].id}`}>
                    {mergeRequests[0].mergeToName}
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-merge-request" fontSize="sm" fontWeight="bold">
                    Unknown merge request
                  </Text>
                ),
              title: (mergeRequests: GetMergeRequestsResponse[number][]) =>
                mergeRequests[0]?.mergeToName ?? 'Unknown merge request',
            }}
            errorElement={<ErrorBoundary />}
          />
        </Route>
      )}
      <Route
        path="competitions"
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/competitions" to="/competitions">
              Competitions
            </BreadcrumbLink>
          ),
          title: () => 'Competitions',
        }}
        errorElement={<ErrorBoundary />}
      >
        <Route
          index
          loader={competitionsLoader(queryClient)}
          element={
            <Suspense fallback={<Loading />}>
              <Competitions />
            </Suspense>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path=":competitionId"
          loader={competitionLoader(queryClient)}
          handle={{
            crumb: (competition: CompetitionDetailed | undefined) =>
              competition ? (
                <BreadcrumbLink key={competition.id} to={`/competitions/${competition.id}`}>
                  {competition.name}
                </BreadcrumbLink>
              ) : (
                <Text key="unknown-competition" fontSize="sm" fontWeight="bold">
                  Unknown competition
                </Text>
              ),
            title: (competition: CompetitionDetailed | undefined) => competition?.name ?? 'Unknown competition',
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <CompetitionDetails />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="edit"
            loader={competitionEditLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <CompetitionEdit />
              </Suspense>
            }
            handle={{
              crumb: ({ competition }: { competition: CompetitionDetailed | undefined }) =>
                competition ? (
                  <BreadcrumbLink key={`edit-${competition.id}`} to={`/competitions/${competition.id}/edit`}>
                    Edit
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-competition-edit" fontSize="sm" fontWeight="bold">
                    Edit
                  </Text>
                ),
              title: ({ initialCompetitionDetails }: { initialCompetitionDetails: CompetitionDetailed | undefined }) =>
                initialCompetitionDetails?.name ?? 'Unknown competition',
            }}
            errorElement={<ErrorBoundary />}
          />
          {/* Competition Stage */}
          <Route
            path="stage/:stageId"
            loader={competitionStageLoader(queryClient)}
            handle={{
              crumb: (competitionStage: CompetitionStageDetailed | undefined) =>
                competitionStage ? (
                  <BreadcrumbLink
                    key={competitionStage.id}
                    to={`/competitions/${competitionStage.competition.id}/stage/${competitionStage.id}`}
                  >
                    {competitionStage?.name}
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-competition-stage" fontSize="sm" fontWeight="bold">
                    Unknown competition stage
                  </Text>
                ),
              title: (competitionStage: CompetitionStageDetailed | undefined) =>
                competitionStage?.name ?? 'Unknown competition stage',
            }}
            errorElement={<ErrorBoundary />}
          >
            <Route
              path="edit"
              loader={competitionStageEditLoader(queryClient)}
              element={
                <Suspense fallback={<Loading />}>
                  <CompetitionStageEdit />
                </Suspense>
              }
              handle={{
                crumb: ({
                  competitionStage: competitionStage,
                }: {
                  competitionStage: CompetitionStageDetailed | undefined
                }) =>
                  competitionStage ? (
                    <BreadcrumbLink
                      key={`edit-stage-${competitionStage.id}`}
                      to={`/competitions/${competitionStage.competition.id}/stage/${competitionStage.id}/edit`}
                    >
                      Edit
                    </BreadcrumbLink>
                  ) : (
                    <Text key="unknown-competition-stage-edit" fontSize="sm" fontWeight="bold">
                      Edit
                    </Text>
                  ),
                title: ({
                  initialCompetitionStageDetails,
                }: {
                  initialCompetitionStageDetails: CompetitionStageDetailed | undefined
                }) => initialCompetitionStageDetails?.name ?? 'Unknown competition stage',
              }}
              errorElement={<ErrorBoundary />}
            />
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <StageDetails />
                </Suspense>
              }
              errorElement={<ErrorBoundary />}
            ></Route>

            {/* Competition Stage Pools */}
            <Route
              path="pool/:poolId"
              loader={competitionStagePoolLoader(queryClient)}
              handle={{
                crumb: (competitionStagePool: CompetitionPoolDetailed | undefined) =>
                  competitionStagePool ? (
                    <BreadcrumbLink
                      key={competitionStagePool.id}
                      // eslint-disable-next-line max-len
                      to={`/competitions/${competitionStagePool.competition.id}/stage/${competitionStagePool.competitionStage.id}/pool/${competitionStagePool.id}`}
                    >
                      {competitionStagePool?.name}
                    </BreadcrumbLink>
                  ) : (
                    <Text key="unknown-competition-stage-pool" fontSize="sm" fontWeight="bold">
                      Unknown competition stage pool
                    </Text>
                  ),
                title: (competitionStage: CompetitionPoolDetailed | undefined) =>
                  competitionStage?.name ?? 'Unknown competition stage pool',
              }}
              errorElement={<ErrorBoundary />}
            >
              <Route
                index
                element={
                  <Suspense fallback={<Loading />}>
                    <StagePoolDetails />
                  </Suspense>
                }
                errorElement={<ErrorBoundary />}
              ></Route>
              <Route
                path="edit"
                loader={competitionPoolEditLoader(queryClient)}
                element={
                  <Suspense fallback={<Loading />}>
                    <CompetitionPoolEdit />
                  </Suspense>
                }
                handle={{
                  crumb: ({
                    competitionPool: competitionPool,
                  }: {
                    competitionPool: CompetitionPoolDetailed | undefined
                  }) =>
                    competitionPool ? (
                      <BreadcrumbLink
                        key={`edit-pool-${competitionPool.id}`}
                        // eslint-disable-next-line max-len
                        to={`/competitions/${competitionPool.competition.id}/stage/${competitionPool.competitionStage.id}/pool/${competitionPool.id}/edit`}
                      >
                        Edit
                      </BreadcrumbLink>
                    ) : (
                      <Text key="unknown-competition-pool-edit" fontSize="sm" fontWeight="bold">
                        Edit
                      </Text>
                    ),
                  title: ({
                    initialCompetitionStageDetails,
                  }: {
                    initialCompetitionStageDetails: CompetitionPoolDetailed | undefined
                  }) => initialCompetitionStageDetails?.name ?? 'Unknown competition pool',
                }}
                errorElement={<ErrorBoundary />}
              />
            </Route>
          </Route>
          {/* Competition Teams */}
          <Route
            path="team/:teamId"
            loader={competitionTeamLoader(queryClient)}
            handle={{
              crumb: (competitionTeam: CompetitionTeamDetailed | undefined) =>
                competitionTeam ? (
                  <BreadcrumbLink
                    key={competitionTeam.id}
                    to={`/competitions/${competitionTeam.competition.id}/team/${competitionTeam.id}`}
                  >
                    {competitionTeam?.name}
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-competition-team" fontSize="sm" fontWeight="bold">
                    Unknown competition team
                  </Text>
                ),
              title: (competitionTeam: CompetitionTeamDetailed | undefined) =>
                competitionTeam?.name ?? 'Unknown competition team',
            }}
            errorElement={<ErrorBoundary />}
          >
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <CompetitionTeamDetails />
                </Suspense>
              }
              errorElement={<ErrorBoundary />}
            ></Route>
            <Route
              path="edit"
              loader={competitionTeamEditLoader(queryClient)}
              element={
                <Suspense fallback={<Loading />}>
                  <CompetitionTeamEdit />
                </Suspense>
              }
              handle={{
                crumb: ({
                  competitionTeam: competitionTeam,
                }: {
                  competitionTeam: CompetitionTeamDetailed | undefined
                }) =>
                  competitionTeam ? (
                    <BreadcrumbLink
                      key={`edit-team-${competitionTeam.id}`}
                      to={`/competitions/${competitionTeam.competition.id}/team/${competitionTeam.id}/edit`}
                    >
                      Edit
                    </BreadcrumbLink>
                  ) : (
                    <Text key="unknown-competition-team-edit" fontSize="sm" fontWeight="bold">
                      Edit
                    </Text>
                  ),
                title: ({
                  initialCompetitionTeamDetails,
                }: {
                  initialCompetitionTeamDetails: CompetitionTeamDetailed | undefined
                }) => initialCompetitionTeamDetails?.name ?? 'Unknown competition team',
              }}
              errorElement={<ErrorBoundary />}
            />
          </Route>
        </Route>
      </Route>
      <Route
        path="matches"
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/matches" to="/matches">
              Matches
            </BreadcrumbLink>
          ),
          title: () => 'Matches',
        }}
        errorElement={<ErrorBoundary />}
      >
        <Route
          index
          loader={matchesLoader(queryClient)}
          element={
            <Suspense fallback={<Loading />}>
              <Matches />
            </Suspense>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path=":matchId"
          loader={matchDetailsLoader(queryClient)}
          handle={{
            crumb: (match: MatchDetailed | undefined) =>
              match ? (
                <BreadcrumbLink key={match.id} to={`/matches/${match.id}`}>
                  {match.title}
                </BreadcrumbLink>
              ) : (
                <Text key="unknown-match" fontSize="sm" fontWeight="bold">
                  Unknown match
                </Text>
              ),
            title: (match: MatchDetailed | undefined) => match?.title ?? 'Unknown match',
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <MatchDetails />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="edit"
            loader={matchEditLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <MatchEdit />
              </Suspense>
            }
            handle={{
              crumb: ({ initialMatchDetails }: { initialMatchDetails: MatchDetailed | undefined }) =>
                initialMatchDetails ? (
                  <BreadcrumbLink key={`edit-${initialMatchDetails.id}`} to={`/matches/${initialMatchDetails.id}/edit`}>
                    Edit
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-match-edit" fontSize="sm" fontWeight="bold">
                    Edit
                  </Text>
                ),
              title: ({ initialMatchDetails }: { initialMatchDetails: MatchDetailed | undefined }) =>
                initialMatchDetails?.title ?? 'Unknown match',
            }}
            errorElement={<ErrorBoundary />}
          />
        </Route>
      </Route>
      {import.meta.env.VITE_USER_MANAGEMENT_ENABLED === 'true' && (
        <Route
          path="users"
          handle={{
            crumb: () => (
              <BreadcrumbLink key="/users" to="/users">
                Users
              </BreadcrumbLink>
            ),
            title: () => 'Users',
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            loader={usersLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <Users />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path=":userId"
            loader={userLoader(queryClient)}
            handle={{
              crumb: (user: UserDetailed | undefined) =>
                user ? (
                  <BreadcrumbLink key={user.user?.id} to={`/users/${user.user?.id}`}>
                    {getFullNameUser(user)}
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-user" fontSize="sm" fontWeight="bold">
                    Unknown user
                  </Text>
                ),
              title: (user: UserDetailed | undefined) => getFullNameUser(user) ?? 'Unknown user',
            }}
            errorElement={<ErrorBoundary />}
          >
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <UserDetails />
                </Suspense>
              }
              errorElement={<ErrorBoundary />}
            />

            <Route
              path="edit"
              loader={userEditLoader(queryClient)}
              element={
                <Suspense fallback={<Loading />}>
                  <UserEdit />
                </Suspense>
              }
              handle={{
                crumb: (user: UserDetailed | undefined) =>
                  user ? (
                    <BreadcrumbLink key={`edit-${user.user?.id}`} to={`/users/${user.user?.id}/edit`}>
                      Edit
                    </BreadcrumbLink>
                  ) : (
                    <Text key="unknown-user-edit" fontSize="sm" fontWeight="bold">
                      Edit
                    </Text>
                  ),
                title: (user: UserDetailed | undefined) =>
                  `${user?.user?.givenName ?? ''} ${user?.user?.familyName ?? ''}`.trim() || 'Unknown user',
              }}
              errorElement={<ErrorBoundary />}
            />
          </Route>
          <Route
            path="create"
            handle={{
              crumb: (user: UserDetailed | undefined) =>
                user ? (
                  <BreadcrumbLink key="/users/create" to={`/users/create`}>
                    New User
                  </BreadcrumbLink>
                ) : (
                  <Text key="create-user" fontSize="sm" fontWeight="bold">
                    Unknown User
                  </Text>
                ),
            }}
            errorElement={<ErrorBoundary />}
          >
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <CreateUser />
                </Suspense>
              }
              errorElement={<ErrorBoundary />}
            />
          </Route>
        </Route>
      )}
      <Route
        path="organisations"
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/organisations" to="/organisations">
              Organisations
            </BreadcrumbLink>
          ),
          title: () => 'Organisations',
        }}
        errorElement={<ErrorBoundary />}
      >
        <Route
          index
          loader={organisationsLoader(queryClient)}
          element={
            <Suspense fallback={<Loading />}>
              <Organisations />
            </Suspense>
          }
          errorElement={<ErrorBoundary />}
        />

        <Route
          path="create"
          handle={{
            crumb: (organisation: OrganisationDetailed | undefined) =>
              organisation ? (
                <BreadcrumbLink key="/organisations/create" to={`/organisations/create`}>
                  New Organisation
                </BreadcrumbLink>
              ) : (
                <Text key="create-organisation" fontSize="sm" fontWeight="bold">
                  Unknown organisation
                </Text>
              ),
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            loader={createOrganisationLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <CreateOrganisation />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
        </Route>

        <Route
          path=":organisationId"
          loader={organisationLoader(queryClient)}
          handle={{
            crumb: (organisation: OrganisationDetailed | undefined) =>
              organisation ? (
                <BreadcrumbLink key={organisation.id} to={`/organisations/${organisation.id}`}>
                  {organisation.name}
                </BreadcrumbLink>
              ) : (
                <Text key="unknown-organisation" fontSize="sm" fontWeight="bold">
                  Unknown organisation
                </Text>
              ),
            title: (organisation: OrganisationDetailed | undefined) => organisation?.name ?? 'Unknown organisation',
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <OrganisationDetails />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="edit"
            loader={organisationEditLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <OrganisationEdit />
              </Suspense>
            }
            handle={{
              crumb: ({ organisation }: { organisation: OrganisationDetailed | undefined }) =>
                organisation ? (
                  <BreadcrumbLink key={`edit-${organisation.id}`} to={`/organisations/${organisation.id}/edit`}>
                    Edit
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-organisation-edit" fontSize="sm" fontWeight="bold">
                    Edit
                  </Text>
                ),
              title: (organisation: OrganisationDetailed | undefined) => organisation?.name ?? 'Unknown organisation',
            }}
            errorElement={<ErrorBoundary />}
          />
        </Route>
      </Route>
      <Route
        path="people"
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/people" to="/people">
              People
            </BreadcrumbLink>
          ),
          title: () => 'People',
        }}
        errorElement={<ErrorBoundary />}
      >
        <Route
          index
          loader={peopleLoader(queryClient)}
          element={
            <Suspense fallback={<Loading />}>
              <People />
            </Suspense>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path=":personId"
          loader={personLoader(queryClient)}
          handle={{
            crumb: (person: PersonDetailed | undefined) =>
              person ? (
                <BreadcrumbLink key={person.id} to={`/people/${person.id}`}>
                  {person.name}
                </BreadcrumbLink>
              ) : (
                <Text key="unknown-person" fontSize="sm" fontWeight="bold">
                  Unknown person
                </Text>
              ),
            title: (person: PersonDetailed | undefined) => person?.name ?? 'Unknown person',
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <PersonDetails />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="edit"
            loader={personEditLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <PersonEdit />
              </Suspense>
            }
            handle={{
              crumb: (person: PersonDetailed | undefined) =>
                person ? (
                  <BreadcrumbLink key={`edit-${person.id}`} to={`/people/${person.id}/edit`}>
                    Edit
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-person-edit" fontSize="sm" fontWeight="bold">
                    Edit
                  </Text>
                ),
              title: (person: PersonDetailed | undefined) =>
                person?.name || `${person?.firstName ?? ''} ${person?.lastName ?? ''}`.trim() || 'Unknown person',
            }}
            errorElement={<ErrorBoundary />}
          />
        </Route>
      </Route>
      <Route
        path="teams"
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/teams" to="/teams">
              Teams
            </BreadcrumbLink>
          ),
          title: () => 'Teams',
        }}
        errorElement={<ErrorBoundary />}
      >
        <Route
          index
          loader={teamsLoader(queryClient)}
          element={
            <Suspense fallback={<Loading />}>
              <Teams />
            </Suspense>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path=":teamId"
          loader={teamLoader(queryClient)}
          handle={{
            crumb: (team: TeamDetailed | undefined) =>
              team ? (
                <BreadcrumbLink key={team.id} to={`/teams/${team.id}`}>
                  {team.name}
                </BreadcrumbLink>
              ) : (
                <Text key="unknown-team" fontSize="sm" fontWeight="bold">
                  Unknown team
                </Text>
              ),
            title: (team: TeamDetailed | undefined) => team?.name ?? 'Unknown team',
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <TeamDetails />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="edit"
            loader={teamEditLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <TeamEdit />
              </Suspense>
            }
            handle={{
              crumb: ({ initialTeamDetails }: { initialTeamDetails: TeamDetailed | undefined }) =>
                initialTeamDetails ? (
                  <BreadcrumbLink key={`edit-${initialTeamDetails.id}`} to={`/teams/${initialTeamDetails.id}/edit`}>
                    Edit
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-team-edit" fontSize="sm" fontWeight="bold">
                    Edit
                  </Text>
                ),
              title: ({ initialTeamDetails }: { initialTeamDetails: TeamDetailed | undefined }) =>
                initialTeamDetails?.name ?? 'Unknown team',
            }}
            errorElement={<ErrorBoundary />}
          />
        </Route>
      </Route>
      <Route
        path="venues"
        handle={{
          crumb: () => (
            <BreadcrumbLink key="/venues" to="/venues">
              Venues
            </BreadcrumbLink>
          ),
          title: () => 'Venues',
        }}
        errorElement={<ErrorBoundary />}
      >
        <Route
          index
          loader={venuesLoader(queryClient)}
          element={
            <Suspense fallback={<Loading />}>
              <Venues />
            </Suspense>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path=":venueId"
          loader={venueLoader(queryClient)}
          handle={{
            crumb: (venue: VenueDetailed | undefined) =>
              venue ? (
                <BreadcrumbLink key={venue.id} to={`/venues/${venue.id}`}>
                  {venue.name}
                </BreadcrumbLink>
              ) : (
                <Text key="unknown-venue" fontSize="sm" fontWeight="bold">
                  Unknown venue
                </Text>
              ),
            title: (venue: VenueDetailed | undefined) => venue?.name ?? 'Unknown venue',
          }}
          errorElement={<ErrorBoundary />}
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <VenueDetails />
              </Suspense>
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="edit"
            loader={venueEditLoader(queryClient)}
            element={
              <Suspense fallback={<Loading />}>
                <VenueEdit />
              </Suspense>
            }
            handle={{
              crumb: (venue: VenueDetailed | undefined) =>
                venue ? (
                  <BreadcrumbLink key={`edit-${venue.id}`} to={`/venues/${venue.id}/edit`}>
                    Edit
                  </BreadcrumbLink>
                ) : (
                  <Text key="unknown-venue-edit" fontSize="sm" fontWeight="bold">
                    Edit
                  </Text>
                ),
              title: (venue: VenueDetailed | undefined) => venue?.name ?? 'Unknown venue',
            }}
            errorElement={<ErrorBoundary />}
          />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/match-centre" replace />} />
    </Route>
  )
)

const params = new URL(document.location.href).searchParams
const code = params.get('code')
const tokens = getTokens()

export function App() {
  const [authenticated, setAuthenticated] = useState(false)
  const [authenticating, setAuthenticating] = useState(code !== null)

  useEffect(() => {
    const check = async () => {
      if (!tokens) {
        setAuthenticated(false)
      } else {
        setAuthenticating(true)
        const result = await checkTokens(tokens.accessToken, tokens.refreshToken)
        setAuthenticated(result)
        setAuthenticating(false)
      }
    }
    check()
  }, [])

  useEffect(() => {
    const execute = async (c: string) => {
      try {
        await login(c)
        setAuthenticated(true)
      } catch (err) {
        // console.log(err)
      }
      setAuthenticating(false)
    }
    if (code !== null) {
      window.history.replaceState({}, '', '/')
      execute(code)
    }
  }, [])

  if (authenticating) return <Authenticating />

  if (!authenticated) return <Login />

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
