import type { GetUsersResponse } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

function getUsers(): Promise<GetUsersResponse> {
  const tokens = getTokens()
  const url = new URL(`${import.meta.env.VITE_API_URL}admin/users`)

  return fetch(url.toString(), {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const usersQuery = () => ({
  queryKey: [],
  queryFn: () => getUsers(),
})
