import { Button, Flex, Heading, HStack, Icon, Link, List, ListIcon, ListItem, Stack } from '@chakra-ui/react'
import type { EntityReference } from '@clsplus/api-types/api-admin'
import { faLink } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { QueryClient } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useParams } from 'react-router-dom'

import { competitionDetailsQuery } from '../../../api/get-competition-details'
import { DetailValue } from '../../../components/detail-value'
import { HasMCAccess, MCRequiredLevelEnum } from '../../../helpers/auth'
import { RequestType, useCompetitionSync } from '../../../helpers/competitionSync'
import { formatShortDate } from '../../../helpers/datetime'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    return await queryClient.ensureQueryData(competitionDetailsQuery(params.competitionId || ''))
  }

export default function CompetitionDetails() {
  const params = useParams()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const { sendRefreshDLRequest, sending } = useCompetitionSync()

  const { data: competition } = useQuery({
    ...competitionDetailsQuery(params.competitionId || ''),
    initialData,
  })

  const generateCompSubLinks = (items: EntityReference[] | undefined, subLink: 'stage' | 'team') => {
    const listItems = items?.map(subItem => {
      const itemContents = (
        <Link fontWeight={'normal'} as={RouterLink} to={`/competitions/${competition.id}/${subLink}/${subItem.id}`}>
          {subItem.name}
        </Link>
      )
      return (
        <ListItem key={subItem.id}>
          <ListIcon as={FontAwesomeIcon} icon={faLink} color="gray.400" />
          {itemContents}
        </ListItem>
      )
    })
    return <List>{listItems}</List>
  }

  return (
    <>
      <Flex w="100%" justifyContent="space-between" alignItems="baseline">
        <Heading as="h2" size="lg">
          {competition.name}
        </Heading>

        <HasMCAccess minLevel={MCRequiredLevelEnum['clsp-superadmin']}>
          <HStack spacing={4}>
            <Button
              onClick={() => sendRefreshDLRequest(RequestType.CompetitorsRequest, competition.id)}
              isDisabled={sending}
            >
              SDS Refresh
            </Button>
            <Button
              as={RouterLink}
              to={`/competitions/${competition.id}/edit`}
              variant="primary"
              minW={32}
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fad', 'pen-to-square']} />}
            >
              Edit
            </Button>
          </HStack>
        </HasMCAccess>
      </Flex>
      <Stack w="100%" mt={6} spacing={6}>
        <DetailValue label="CLS+ ID" value={competition.id} />
        <DetailValue label="SDS ID" value={competition.integrationIds?.SDS} />
        <DetailValue label="Coverage level" value={competition.coverageLevel?.name} />
        <DetailValue label="Organisation" value={competition.organisation?.name} />
        <DetailValue label="Short name" value={competition.shortName} />
        <DetailValue
          label="Start date"
          value={competition.startDate && formatShortDate(new Date(competition.startDate))}
        />
        <DetailValue label="End date" value={competition.endDate && formatShortDate(new Date(competition.endDate))} />
        <DetailValue label="Default match format" value={competition.defaultMatchFormat.name} />
        <DetailValue label="Default match class" value={competition.defaultMatchClass.name} />
        <DetailValue label="Age group" value={competition.ageGroup?.name} />
        <DetailValue label="Gender" value={competition.gender?.name} />
        <DetailValue label="Is official" value={competition.isUnofficial ? 'No' : 'Yes'} />
        <DetailValue label="Teams" value={generateCompSubLinks(competition.teams, 'team')} />
        <DetailValue label="Stages" value={generateCompSubLinks(competition.stages, 'stage')} />
      </Stack>
    </>
  )
}
