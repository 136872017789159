import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Switch } from '@chakra-ui/react'
import type { UpdateUserRequest } from '@clsplus/api-types/api-admin'
import { zodResolver } from '@hookform/resolvers/zod'
import type { QueryClient } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { userDetailsQuery } from '../../../api/get-user-details'
import { updateUserDetails } from '../../../api/update-user-details'

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) =>
    await queryClient.ensureQueryData(userDetailsQuery(params.userId || ''))

const userEditSchema = z.object({
  givenName: z.string(),
  familyName: z.string(),
  enabled: z.boolean(),
})

type UserEditFormData = z.infer<typeof userEditSchema>

export default function PersonEdit() {
  const params = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const initialData = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>

  const userId = params.userId || ''

  const { data: userDetails } = useQuery({
    ...userDetailsQuery(userId),
    initialData,
  })

  const updateUserMutation = useMutation({
    mutationFn: (formData: UpdateUserRequest) => updateUserDetails(userId, formData),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['users'] }),
        queryClient.invalidateQueries({ queryKey: ['user', userId] }),
      ])
      revalidator.revalidate()
      navigate(`/users/${userId}`)
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<UserEditFormData>({
    defaultValues: {
      givenName: userDetails.user?.givenName,
      familyName: userDetails.user?.familyName,
      enabled: userDetails.user?.enabled,
    },
    resolver: zodResolver(userEditSchema),
  })

  const handleSubmitClick = (formData: UserEditFormData) => {
    updateUserMutation.mutate(formData)
  }

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        {userDetails.user?.givenName} {userDetails.user?.familyName}
      </Heading>
      <Stack
        as="form"
        noValidate
        id="user-edit"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isInvalid={!!errors.givenName}>
          <FormLabel>Given name</FormLabel>
          <Input {...register('givenName')} />
          {errors.givenName && <FormErrorMessage>{errors.givenName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={!!errors.familyName}>
          <FormLabel>Family name</FormLabel>
          <Input {...register('familyName')} />
          {errors.familyName && <FormErrorMessage>{errors.familyName.message}</FormErrorMessage>}
        </FormControl>
        <FormControl>
          <FormLabel>Enabled</FormLabel>
          <Switch variant="primary" {...register('enabled')} />
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={4} mt={8}>
        <Button
          type="submit"
          form="user-edit"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={updateUserMutation.isLoading}
        >
          Save changes
        </Button>
        <Button
          variant="secondary"
          onClick={() => navigate(`/people/${userId}`)}
          isDisabled={updateUserMutation.isLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
