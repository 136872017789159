import { Checkbox, Flex, Table as ChakraTable, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import type { ColumnDef, RowData, RowSelectionState } from '@tanstack/react-table'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { useMemo, useState } from 'react'

type TableProps<TData extends RowData> = {
  columns: ColumnDef<TData>[]
  data: TData[]
  enableRowSelection?: boolean
  selectionValueId?: string
  maxSelectionSize?: number
  selectionHeaderText?: string
  selectionChange?: (value: string | number | undefined) => void
  initialRowSelectionState?: RowSelectionState
}

export const Table = <TData extends RowData>({
  columns,
  data,
  enableRowSelection,
  initialRowSelectionState,
  selectionValueId,
  maxSelectionSize,
  selectionHeaderText = '',
  selectionChange,
}: TableProps<TData>) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(initialRowSelectionState || {})

  const selectionColumn: ColumnDef<TData> = useMemo(
    () => ({
      id: 'selection',
      header: selectionHeaderText,
      cell: ({ row }) => {
        const rowIdVal = row.getValue(selectionValueId ? selectionValueId : 'id') as string

        return (
          <Flex align="center" justify="center">
            <Checkbox
              isChecked={row.getIsSelected()}
              isDisabled={
                !row.getCanSelect() ||
                (maxSelectionSize !== undefined && !row.getIsSelected() && getSelectionLength() >= maxSelectionSize)
              }
              value={rowIdVal}
              onChange={() => {
                if (selectionChange) {
                  selectionChange(rowIdVal)
                }
                row.toggleSelected()
              }}
            />
          </Flex>
        )
      },
    }),
    []
  )

  const table = useReactTable({
    columns: [...(enableRowSelection ? [selectionColumn] : []), ...columns],
    data,
    state: {
      rowSelection: rowSelection,
    },
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const getSelectionLength = () => {
    return table.getSelectedRowModel().flatRows.length
  }

  return (
    <ChakraTable>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} borderColor="primary.500" color="primary.300">
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map(row => {
          return (
            <Tr key={row.id} backgroundColor={row.getIsSelected() ? 'blue.900' : undefined}>
              {row.getVisibleCells().map(cell => {
                return (
                  <Td key={cell.id} borderColor="primary.600" fontSize="sm" py={4}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                )
              })}
            </Tr>
          )
        })}
      </Tbody>
    </ChakraTable>
  )
}
