import type { MergeEntityType, MergeStatus } from '@clsplus/api-types/entity-merge'

import type { MatchClassId } from '../types/match'

export const MatchClasses: Record<MatchClassId, string> = {
  500: 'OTHER',
  0: 'UNKNOWN',
  2: 'ODI',
  3: 'INTERNATIONAL_T20',
  101: 'LIST_A',
  1: 'INTERNATIONAL',
  100: 'DOMESTIC',
} as const

export const HandedTypeOptions = ['LEFT', 'RIGHT'] as const
export const BowlerTypeOptions = ['WRIST_SPIN', 'FINGER_SPIN', 'SLOW', 'MEDIUM', 'MEDIUM_FAST', 'FAST'] as const
export const BowlerApproachOptions = ['OVER', 'AROUND'] as const
export const BallLengthOptions = ['UNKNOWN', 'BOUNCER', 'YORKER'] as const
export const BowlerBallTypeOptions = ['UNKNOWN', 'STOCK', 'SLOWER', 'VARIATION', 'WRONG_UN'] as const

export const ShotTypeOptions = [
  'UNKNOWN',
  'DEFENSIVE',
  'LEAVE',
  'PADDED',
  'CUT',
  'LATE_CUT',
  'SQUARE_CUT',
  'PULL',
  'HOOK',
  'DRIVE',
  'GLANCE',
  'LEG_GLANCE',
  'RAMP',
  'SWEEP',
  'REVERSE_SWEEP',
  'SWITCH_HIT',
  'PADDLE',
  'FLICK',
  'SCOOP',
] as const

export const ShotContactOptions = [
  'UNKNOWN',
  'GOOD',
  'POOR',
  'PLAY_MISS',
  'INSIDE_EDGE',
  'OUTSIDE_EDGE',
  'HIT_GLOVES',
] as const

export const BodyContactOptions = ['HIT_PAD', 'HIT_BODY', 'HIT_HEAD'] as const
export const FootworkOptions = ['DUCKED', 'BACK_FOOT', 'BACKED_AWAY', 'FRONT_FOOT', 'BATTER_MOVEMENT'] as const
export const ByeBlameOptions = ['NONE', 'WICKET_KEEPER', 'BOWLER'] as const
export const WicketKeeperPositionOptions = ['UP', 'BACK'] as const

export const MatchFormats = ['UNKNOWN', 'MULTIDAY', 'ONE_DAY', 'T20', 'T10', 'THE_HUNDRED'] as const

export const CompetitionGenderTypes = ['MALE', 'FEMALE', 'MIXED'] as const
export const CompetitionAgeGroupTypes = ['SENIOR', 'YOUTH'] as const

export const MatchStaffRoles = ['HEAD_COACH', 'ASSISTANT_COACH', 'TEAM_MANAGER'] as const

export const BreakTypes = [
  'UNKNOWN',
  'DRINKS',
  'LUNCH',
  'TEA',
  'STUMPS',
  'RAIN_DELAY',
  'BAD_LIGHT',
  'INJURY',
  'ON_FIELD_DELAY',
  'OTHER',
  'TIMEOUT',
  'INNINGS_BREAK',
] as const

export const InningsStatus = ['NOT_YET_STARTED', 'IN_PROGRESS', 'COMPLETED', 'UNKNOWN'] as const

export const MatchResultType = ['NO_RESULT', 'RESULT', 'DRAWN', 'POSTPONED', 'ABANDONED', 'TIED', 'FORFEIT'] as const

export const PowerPlayTypes = ['POWER_PLAY_1', 'POWER_PLAY_2', 'POWER_PLAY_3'] as const

export const BoundaryOptions = ['IN', 'NORMAL'] as const
export const OutfieldSpeedOptions = ['SLOW', 'NORMAL', 'FAST'] as const
export const PitchMoistureOptions = ['VERY_DRY', 'DRY', 'NORMAL', 'MOIST'] as const
export const PitchGrassOptions = ['BARREN', 'NORMAL', 'GREEN'] as const
export const PitchQualityOptions = ['NORMAL', 'DETERIORATING', 'SLOW', 'LOW'] as const
export const WeatherTemperatureOptions = ['VERY_HOT', 'HOT', 'WARM', 'MILD', 'COLD', 'VERY_COLD'] as const
export const WeatherAtmosOptions = ['CLEAR', 'PARTLY_CLOUDY', 'CLOUDY'] as const
export const WeatherWindOptions = ['NO_WIND', 'BREEZE', 'WINDY', 'GALE'] as const
export const WeatherRainOptions = ['NO_RAIN', 'SHOWERS', 'RAIN', 'HEAVY_RAIN'] as const

export const TossDecisionOptions = ['BAT', 'BOWL'] as const

export const DismissalMethods = [
  'CAUGHT',
  'LBW',
  'BOWLED',
  'STUMPED',
  'RUN_OUT',
  'HIT_WICKET',
  'RETIRED_HURT',
  'HIT_TWICE',
  'TIMED_OUT',
  'HANDLED_BALL',
  'OBSTRUCTING_FIELD',
  'RETIRED',
  'RETIRED_NO',
  'ABSENT',
] as const

export const ExtrasTypeOptions = ['NO_BALL', 'WIDE', 'BYE', 'LEG_BYE', 'NO_BALL_BYE', 'NO_BALL_LEG_BYE']

export const ExtrasTypeAbbreviations = [
  {
    key: 'NO_BALL',
    value: 'NB',
  },
  {
    key: 'WIDE',
    value: 'WD',
  },
  {
    key: 'BYE',
    value: 'B',
  },
  {
    key: 'LEG_BYE',
    value: 'LB',
  },
  {
    key: 'NO_BALL_BYE',
    value: 'NB+#B',
  },
  {
    key: 'NO_BALL_LEG_BYE',
    value: 'NB+#LB',
  },
]

export const TimeOfDay = {
  DAY: 0,
  'DAY/NIGHT': 1,
  NIGHT: 2,
} as const

export const HandedTypes = {
  LEFT: 0,
  RIGHT: 1,
} as const

export const AgeGroups = {
  SENIOR: 0,
  YOUTH: 1,
} as const

export const Genders = {
  MALE: 0,
  FEMALE: 1,
  MIXED: 2,
} as const

export const CoverageLevels = {
  POST_MATCH: 0,
  CORE: 1,
  ADVANCED: 2,
  FIELDING: 3,
} as const

export const BowlerTypes = {
  WRIST_SPIN: 0,
  FINGER_SPIN: 1,
  SLOW: 2,
  MEDIUM: 3,
  MEDIUM_FAST: 4,
  FAST: 5,
} as const

export const mergeEntityTypeIds = ['PERSON', 'TEAM', 'VENUE'] as const

export const mergeEntityTypes: ReadonlyArray<{ id: MergeEntityType; name: string }> = [
  { id: 'PERSON', name: 'Person' },
  { id: 'TEAM', name: 'Team' },
  { id: 'VENUE', name: 'Venue' },
] as const

export const mergeStatuses: ReadonlyArray<{ id: MergeStatus; name: string }> = [
  { id: 'MERGE_PENDING', name: 'Pending' },
  { id: 'MERGE_IN_PROGRESS', name: 'In progress' },
  { id: 'MERGE_COMPLETE', name: 'Complete' },
  { id: 'MERGE_FAILED', name: 'Failed' },
  { id: 'UNDO_PENDING', name: 'Undo pending' },
  { id: 'UNDO_IN_PROGRESS', name: 'Undo in progress' },
  { id: 'UNDO_COMPLETE', name: 'Undo complete' },
  { id: 'UNDO_FAILED', name: 'Undo failed' },
] as const

export const localStorageKeys = {
  cls_plus_admin_accessToken: 'cls_plus_admin_accessToken',
  cls_plus_admin_idToken: 'cls_plus_admin_idToken',
  cls_plus_admin_refreshToken: 'cls_plus_admin_refreshToken',
  cls_plus_admin_live_monitoring: 'cls_plus_admin_live_monitoring',
} as const
