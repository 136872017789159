import { Flex, Text } from '@chakra-ui/react'

import { useMatch } from '../../../contexts/match.context'
import { getTotalExtras } from '../../../helpers/inning'
import type { Inning } from '../../../types/match'

type ExtrasProps = {
  inning: Inning
  condensed: boolean
}

export const Extras = ({ inning, condensed }: ExtrasProps) => {
  const match = useMatch()

  return (
    <Flex p={2} align="center">
      <Flex flex={{ base: condensed ? 1 : 2, md: condensed ? 1 : 4 }}>
        <Text fontWeight="bold">Extras</Text>
        <Text ml={2}>{getTotalExtras(inning, match.matchConfigs?.noBallRuns)}</Text>
      </Flex>
      <Flex flex={1} justify="center" align="center" fontSize="sm">
        <Text>NB</Text>
        <Text ml={2}>{inning.progressiveScores.noBalls}</Text>
      </Flex>
      <Flex flex={1} justify="center" align="center" fontSize="sm">
        <Text>WD</Text>
        <Text ml={2}>{inning.progressiveScores.wides}</Text>
      </Flex>
      <Flex flex={1} justify="center" align="center" fontSize="sm">
        <Text>B</Text>
        <Text ml={2}>{inning.progressiveScores.byes}</Text>
      </Flex>
      <Flex flex={1} justify="center" align="center" fontSize="sm">
        <Text>LB</Text>
        <Text ml={2}>{inning.progressiveScores.legByes}</Text>
      </Flex>
      <Flex flex={1} justify="center" align="center" fontSize="sm">
        <Text>Pen. Runs</Text>
        <Text ml={2}>{inning.progressiveScores.penaltyRuns}</Text>
      </Flex>
    </Flex>
  )
}
