import { Box, Flex, Heading, HStack, Icon, Tag, TagLabel, Text, Tooltip, useBreakpointValue } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'

import { useMatch } from '../../contexts/match.context'
import { CoverageLevels } from '../../reference'
import type { ConnectionData, MatchConnection, MatchConnectionsProps } from '../../types'

const connectionToolTip = (connection: ConnectionData, title: string, isPreview: boolean) => {
  if (isPreview) {
    return `${title}: ${connection.email}`
  }
  const connectTime = new Date(connection.connectedAt)
  return `
    ${connectTime.toLocaleString('en-GB', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    })}
  `
}

const connectionBackground = (connection: ConnectionData, isPreview: boolean) => {
  const baseColour = connection.isPrimary ? '(56, 161, 105)' : '(237, 137, 54)'
  const altColour = connection.isPrimary ? '(47, 133, 90)' : '(192, 86, 33)'
  if (isPreview) {
    return `rgb${baseColour}`
  }
  // calculate how far along this socket connection is being closed (2hrs from connected time)
  const totalDiff = 7200000 // 2 hrs in milliseconds
  const connectedTime = moment(connection.connectedAt)
  const disconnectTime = connectedTime.add(2, 'hours')
  const diff = disconnectTime.diff(moment())
  const percentageDiff = (diff / totalDiff) * 100
  // eslint-disable-next-line
  return `linear-gradient(90deg, rgb${baseColour} 0%, rgba${baseColour} ${100 - percentageDiff}%, rgb${altColour} ${
    100 - percentageDiff
  }%, rgba${altColour} 100%)`
}

export const MatchConnectionsPreview = ({ match, connections }: MatchConnectionsProps) => {
  const isMediumViewport = useBreakpointValue({ base: false, md: true, lg: true, xl: false })

  return (
    <Box backgroundColor="primary.500" borderRadius="6px" padding={2} alignSelf="center" height="3em">
      <HStack alignItems="center">
        {isMediumViewport && (
          <Heading as="h3" size="sm" textAlign="center" marginRight="2">
            Connections
          </Heading>
        )}
        <MatchConnections isPreview match={match} connections={connections} />
      </HStack>
    </Box>
  )
}

export const MatchConnections = ({ match, connections, isPreview = false }: MatchConnectionsProps) => {
  const visibleConns: Array<JSX.Element> = []

  const labels = generateLabels(
    connections,
    match.matchConfigs?.coverageLevelId || 0,
    match.matchStatusId || 0,
    isPreview
  )

  for (const [key, value] of labels) {
    visibleConns.push(
      <Flex key={`MatchConnections_${key}`} align="center" mt={!isPreview ? 3 : 0}>
        {!isPreview ? (
          <Text fontSize="md" mr={2}>
            {value.title}:
          </Text>
        ) : null}

        {value.connectionJSX}
      </Flex>
    )
  }

  return (
    <Flex width="100%" flexDirection={isPreview ? 'row' : 'column'} overflow="hidden">
      {!isPreview && (
        <Heading as="h3" size="md" textAlign="center">
          Connections
        </Heading>
      )}

      {visibleConns}
    </Flex>
  )
}

const generateLabels = (
  connections: MatchConnection | undefined,
  coverageLevelId: number,
  matchStatusId: number,
  isPreview = false
) => {
  enum connType {
    core = 'core',
    advanced = 'advanced',
    fielding = 'fielding',
  }

  type titleType = 'Core' | 'Advanced' | 'Fielding' | 'Unknown'

  const titleMap = new Map<connType, titleType>([
    [connType.core, 'Core'],
    [connType.advanced, 'Advanced'],
    [connType.fielding, 'Fielding'],
  ])

  const connectionsEls = new Map<connType, { title: titleType; connectionJSX: JSX.Element[] }>()

  for (const con of Object.values(connType)) {
    const conValue = connections?.connections[con]
    const title = titleMap.get(con) || 'Unknown'
    if (conValue && conValue.length > 0) {
      connectionsEls.set(con, {
        title: title,
        connectionJSX: conValue.map(conn => (
          <Tag
            key={conn.connectionId}
            size="lg"
            background={connectionBackground(conn, isPreview)}
            borderRadius="full"
            mr={2}
          >
            <Tooltip label={connectionToolTip(conn, title, isPreview)} placement="top">
              <TagLabel fontSize="sm">{isPreview ? conn.email.charAt(0).toUpperCase() : conn.email}</TagLabel>
            </Tooltip>
          </Tag>
        )),
      })
    } else if (
      (matchStatusId === 1 || matchStatusId === 2) &&
      (con === connType.core ||
        (con === connType.advanced && coverageLevelId >= CoverageLevels.ADVANCED) ||
        (con === connType.fielding && coverageLevelId >= CoverageLevels.FIELDING))
    ) {
      connectionsEls.set(con, {
        title: title,
        connectionJSX: [
          <Tag key={`${con}-missing`} size="lg" background={'red.600'} borderRadius="full" mr={2}>
            <Tooltip label={`${con} mode has no connection`} placement="top">
              <TagLabel fontSize="sm" width="100%" textAlign={'center'}>
                {isPreview ? (
                  '-'
                ) : (
                  <>
                    <Icon as={FontAwesomeIcon} icon={['fad', 'triangle-exclamation']} mr={2} />
                    {'No Connection'}
                  </>
                )}
              </TagLabel>
            </Tooltip>
          </Tag>,
        ],
      })
    }
  }

  return connectionsEls
}
