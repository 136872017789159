// These types should be removed and replaced once we have generated types for CLS+ backend APIs

export type MatchConditions = {
  id: string
  timestamp: string
  boundaryTypeId: number | null
  outfieldSpeedTypeId: number | null
  pitchMoistureTypeId: number | null
  pitchGrassTypeId: number | null
  pitchQualityTypeId: number | null
  temperatureTypeId: number | null
  windTypeId: number | null
  rainTypeId: number | null
  atmosTypeId: number | null
}

export type MatchDate = {
  id: string
  startDateTime: string | null
  endDateTime: string | null
  matchDayNum: number | null
  conditions: MatchConditions[] | null
}

export type Person = {
  id: string | null
  firstName: string | null
  middleName: string | null
  lastName: string | null
  fullName: string | null
  dob: string | null
  notes: string | null
  gender: string | null
  email: string | null
  alphaName: string | null
  cardNameF: string | null
  cardNameS: string | null
}

export type MatchOfficial = {
  id: string
  matchOfficialTypeId: number | null
  cardNameF: string | null
  cardNameS: string | null
  official: {
    id: string | null
    person: Person | null
  }
}

export type MatchBreak = {
  id: string
  startTime: string | null
  endTime: string | null
  matchBreakTypeId: number | null
}

export type MatchDlsSuspension = {
  id: string
  inningsId: string | null
  oversAt: number | null
  runs: number | null
  wickets: number | null
  oversRemaining: number | null
}

export type MatchDls = {
  id: string
  active: boolean
  targetScore: number | null
  targetOvers: number | null
  matchDlsSuspensions: MatchDlsSuspension[]
}

export type PenaltyRuns = {
  id: string
  timestamp: string | null
  matchTeamId: string | null
  runs: number | null
  inningsNum: number | null
}

export type CompetitionStage = {
  id: string
  name: string | null
  competition: {
    id: string
    name: string | null
    competitionGenderId: number | null
    competitionAgeGroupId: number | null
    isUnofficial: boolean
  } | null
}

export type CompetitionPool = {
  id: string
  name: string | null
}

export enum CoverageLevelId {
  POST_MATCH = 0,
  CORE = 1,
  ADVANCED = 2,
  FIELDING = 3,
}

export type MatchConfigs = {
  id: string
  bowlerConsecutiveOvers: boolean
  maxOvers: number | null
  ballsPerOver: number | null
  noBallRuns: number | null
  maxBattingReviewsPerInnings: number | null
  maxBowlingReviewsPerInnings: number | null
  coverageLevelId: CoverageLevelId | null
}

export enum ActiveReason {
  UNKNOWN = 0,
  STARTING_TEAM = 1,
  SUB_UNUSED = 2,
  CONCUSSION_OFF = 3,
  CONCUSSION_ON = 4,
  SUPER_SUB_OFF = 5,
  SUPER_SUB_ON = 6,
}

export type MatchPlayer = {
  id: string
  cardNameF: string | null
  cardNameS: string | null
  shirtNumber: number | null
  selectionNumber: number | null
  wicketKeeper: boolean
  captain: boolean
  viceCaptain: boolean
  isPlayerOfMatch: boolean
  substitute: boolean
  activeStatus: boolean
  activeReasonId?: ActiveReason
  playerFlags: number | null
  player: {
    id: string
    battingHandedId: number | null
    bowlingHandedId: number | null
    bowlingTypeId: number | null
    primaryThrowingArmId: number | null
    person: Person | null
  }
}

export type MatchStaff = {
  id: string
  matchTeamRoleId: number | null
  person: Person | null
}

export type ShotSides = {
  id: string
  offSide: number
  onSide: number
}

export type ShotZones = {
  id: string
  squareLeg: number
  fineLeg: number
  thirdMan: number
  point: number
  cover: number
  midOff: number
  midOn: number
  midWicket: number
  longOn: number
  longOff: number
  deepCover: number
  deepPoint: number
  slips: number
  gully: number
  deepSquareLeg: number
  deepMidWicket: number
}

export type ProgressiveScores = {
  id: string
  byes: number
  extras: number
  legByes: number
  noBalls: number
  penaltyRuns: number
  runs: number
  wickets: number
  wides: number
  oversBowled: string
}

export type DismissalFielder = {
  player: {
    id: string
    dismissalId: string
    playerMpId: string
    dismisserRoleId: number
  }
  order: number
}

export type Dismissal = {
  id: string
  dismissalTypeId: number
  battersCrossed: boolean
  wicketNumber: number
  fowRuns: number
  fowOver: string
  bowler: string
  batterMp: string
  fielders: DismissalFielder[] | null
}

export type BattingMinutes = {
  id: string
  startTimestamp: string | null
  endTimestamp: string | null
  order: number
}

export type BattingPerformanceBase = {
  id: string
  order: number
  balls: number | null
  didNotBat: boolean | null
  text: string | null
  totalMins: number | null
  runs: number | null
  dots: number | null
  ones: number | null
  twos: number | null
  threes: number | null
  fours: number | null
  sixes: number | null
  notOut: boolean
  onStrike: boolean
  playerMp: string | null
  attackingShots: number | null
  shotSides: ShotSides | null
  shotZones: ShotZones | null
}

export type BattingPerformance = BattingPerformanceBase & {
  dismissal: Dismissal | null
  battingPerformanceInstances: BattingPerformanceInstance[]
}

export type BattingPerformanceInstance = BattingPerformanceBase & {
  start: string
  battingMinutes: BattingMinutes[]
}

export type Extras = {
  id: string
  wides: number | null
  noBalls: number | null
}

export type BowlingPerformanceBase = {
  id: string
  order: number
  maidens: number | null
  isBowling: boolean | null
  runs: number | null
  dots: number | null
  overs: string | null
  wickets: number | null
  onStrike: boolean | null
  appeals: number | null
  aroundTheWicket: number | null
  bouncers: number | null
  edges: number | null
  overTheWicket: number | null
  playAndMisses: number | null
  yorkers: number | null
  extras: Extras | null
}

export type BowlingPerformanceSpell = BowlingPerformanceBase & {
  instanceNumber: number | null
  end: string | null
  endTimestamp: string | null
  start: string | null
  startTimestamp: string | null
  endBall: number | null
  endOver: number | null
  startBall: number | null
  startOver: number | null
}

export type BowlingPerformance = BowlingPerformanceBase & {
  bowlingPerformanceSpells: BowlingPerformanceSpell[]
  playerMp: string | null
}

export type PartnershipPlayer = {
  id: string
  playerMp: string | null
  battingOrder: number | null
  status: number
  runs: number | null
  balls: number | null
  dots: number | null
  ones: number | null
  twos: number | null
  threes: number | null
  fours: number | null
  sixes: number | null
  strikeRate: number | null
  instanceNumber: number | null
}

export type BattingPartnership = {
  id: string
  wicketNumber: number
  extraRuns: number | null
  start: string
  startTimestamp: string
  end: string | null
  endTimestamp: string | null
  balls: number | null
  totalRuns: number | null
  partnershipPlayers: PartnershipPlayer[] | null
}

export type PowerPlay = {
  id: string
  powerPlayTypeId: number | null
  start: string | null
  end: string | null
}

export type Inning = {
  id: string
  inningsNumber: number
  inningsMatchOrder: number
  battingTeamId: string | null
  likelyNextBatterMpId: string | null
  likelyNextBowlerMpId: string | null
  closeReasonId: number
  createdTime: string | null
  endTime: string | null
  startTime: string | null
  followOn: string | null
  superOver: string | null
  inningsStatusId: number
  battingReviewsRemaining: number
  bowlingReviewsRemaining: number
  progressiveScores: ProgressiveScores
  battingPerformances: BattingPerformance[]
  bowlingPerformances: BowlingPerformance[]
  partnerships: BattingPartnership[]
  powerPlays: PowerPlay[] | null
}

export type MatchTeam = {
  id: string
  isHome: boolean
  teamId: string | null
  name: string | null
  shortName: string | null
  matchPlayers?: MatchPlayer[]
  matchStaff?: MatchStaff[]
  innings: Inning[]
}

export type VenueEnd = {
  id: string
  name: string
}

export type Venue = {
  id: string
  name: string
  fullName: string | null
  knownAs: string | null
  address: string | null
  addressId: string | null
  venueEnds: VenueEnd[]
}

export type MatchClassId = 500 | 0 | 2 | 3 | 101 | 1 | 100

export type MatchNote = {
  id: string | undefined
  order: number
  detail: string
}

export type InningNotes = {
  name: string
  inningsMatchOrder: number
  inningsId: string
  notes: MatchNote[]
}

export type Match = {
  id: string
  dataProvider: 'sds' | 'clsp'
  title: string | null
  matchStatusId: number | null
  tossDecisionId: number | null
  wasLiveScored: boolean | null
  isLiveScore: boolean | null
  optionFlags: number | null
  scoresUpdated?: string | null
  likelyRainDelay: boolean | null
  timeZone: string | null
  matchClassId: MatchClassId | null
  matchFormatId: number | null
  matchResultTypeId: number | null
  winningMatchTeamId: string | null
  description: string | null
  roundId: string | null
  matchNumber: number | null
  battedFirstMatchTeamId: string | null
  wonTossMatchTeamId: string | null
  confirmStatusId: number
  matchNotes: InningNotes[] | string | null // Not 100% sure that this type is correct...
  timeOfDayId: number | null
  venue: Venue | null
  matchDates: MatchDate[]
  matchOfficials: MatchOfficial[]
  matchBreaks: MatchBreak[]
  matchDls: MatchDls | null
  penaltyRuns: PenaltyRuns[]
  competitionStage: CompetitionStage | null
  competitionPool: CompetitionPool | null
  matchConfigs: MatchConfigs | null
  matchTeams: MatchTeam[]
}
