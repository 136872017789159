import {
  Box,
  Button,
  Code,
  Flex,
  Heading,
  HStack,
  Icon,
  ListItem,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  UnorderedList,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import { isNil, reverse } from 'lodash'
import { useState } from 'react'
import { Link as RouterLink, useRevalidator, useSearchParams } from 'react-router-dom'
import { useInterval } from 'usehooks-ts'

import { ballInningsQuery } from '../../api/get-ball-innings'
import { matchCentreMatchQuery } from '../../api/get-match-centre-match'
import { matchIdsQuery } from '../../api/get-match-ids'
import { Last12Balls, onStrikerOrdered } from '../../components/live-panel'
import { MatchConnectionsPreview } from '../../components/match-connections'
import { MatchFeedsPreview } from '../../components/match-feeds'
import { ScoreBox } from '../../components/score-box'
import type { MatchLoaderData } from '../../contexts/match.context'
import { getTokens } from '../../helpers/auth'
import { confirmedBallsGrouped } from '../../helpers/ball'
import { getCurrentBatterPerformances, getCurrentBowlerPerformances } from '../../helpers/inning'
import { getActiveInning } from '../../helpers/match'
import { getPlayer, getPlayerDisplayName } from '../../helpers/player'
import { theme } from '../../theme'
import type { FeedSubscription, MatchConnection, SubscriberInfo } from '../../types'
import { CoverageLevelId } from '../../types/match'
import { NonIdealState } from '../../ui/non-ideal-state'
import { CoverageLevelIndicator } from '../coverage-level-indicator'
import { EntityLink } from '../entity-link'

const tokens = getTokens()

type LiveMonitorGameProps = {
  id: string
  loadedData: MatchLoaderData
}

const LiveMonitoredGame = ({ id, loadedData }: LiveMonitorGameProps) => {
  const [connectionData, setConnectionData] = useState<MatchConnection>()
  const [feedData, setFeedData] = useState<SubscriberInfo[]>([])

  const [showAdvancedData, setShowAdvancedData] = useState(false)
  const [autoUpdate] = useState(true)
  const { advancedModeData, coreModeData, advancedModeBalls, coreModeBalls } = loadedData

  const [searchParams] = useSearchParams()
  const params = Object.fromEntries(searchParams)
  const bypassLookup = params.bypassLookup === 'true'

  const revalidator = useRevalidator()

  const isExtraSmallViewport = useBreakpointValue({ base: true, sm: false })

  const { data: matchAdvanced } = useQuery({
    ...matchCentreMatchQuery({
      id: id || '',
      mode: advancedModeData.matchConfigs?.coverageLevelId === CoverageLevelId.POST_MATCH ? 'postMatch' : 'advanced',
      bypassLookup,
    }),
    initialData: advancedModeData,
    refetchInterval: autoUpdate ? 10000 : false,
    enabled: advancedModeData.matchConfigs?.coverageLevelId !== CoverageLevelId.CORE,
  })
  const { data: matchCore } = useQuery({
    ...matchCentreMatchQuery({ id: id || '', mode: 'core', bypassLookup }),
    initialData: coreModeData,
    refetchInterval: autoUpdate ? 10000 : false,
    enabled: advancedModeData.matchConfigs?.coverageLevelId !== CoverageLevelId.POST_MATCH,
  })
  const { data: matchIds } = useQuery({
    ...matchIdsQuery({ id: id || '' }),
  })
  const { data: ballInningsAdvanced } = useQuery({
    ...ballInningsQuery({ id: id || '', mode: 'advanced' }),
    initialData: advancedModeBalls,
    enabled: [CoverageLevelId.ADVANCED, CoverageLevelId.FIELDING, null, undefined].includes(
      advancedModeData.matchConfigs?.coverageLevelId
    ),
    refetchInterval: autoUpdate ? 10000 : false,
  })
  const { data: ballInningsCore } = useQuery({
    ...ballInningsQuery({ id: id || '', mode: 'core' }),
    initialData: coreModeBalls,
    enabled: [CoverageLevelId.ADVANCED, CoverageLevelId.FIELDING, null, undefined].includes(
      advancedModeData.matchConfigs?.coverageLevelId
    ),
    refetchInterval: autoUpdate ? 10000 : false,
  })

  // liveInning for each mode
  const liveInningCore = getActiveInning(matchCore)
  const liveInningAdvanced = getActiveInning(matchAdvanced)

  const activeInningBallsCore = confirmedBallsGrouped(
    ballInningsCore.find(inning => inning.inningsId === liveInningCore?.id)?.balls
  )
  const activeInningBallsAdvanced = confirmedBallsGrouped(
    ballInningsAdvanced.find(inning => inning.inningsId === liveInningAdvanced?.id)?.balls
  )

  // activeInningBallKeys for each mode
  const activeInningBallKeysCore = activeInningBallsCore
    ? reverse(Object.keys(activeInningBallsCore).map(key => parseInt(key)))
    : []
  const activeInningBallKeysAdvanced = activeInningBallsAdvanced
    ? reverse(Object.keys(activeInningBallsAdvanced).map(key => parseInt(key)))
    : []

  // Init coverage level check vars
  const isCoreCoverageLevel =
    !isNil(matchCore.matchConfigs) &&
    !isNil(matchCore.matchConfigs.coverageLevelId) &&
    matchCore.matchConfigs?.coverageLevelId <= CoverageLevelId.CORE

  useInterval(async () => {
    async function fetchConnectionData(id: string) {
      const url = `${import.meta.env.VITE_API_URL}matchConnections/${id}`
      const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
      if (response && response.ok) {
        const data = await response.json()
        setConnectionData(data)
      }
    }
    async function fetchFeedData(gameId: string, compId: string | undefined) {
      const compUrl = `${import.meta.env.VITE_API_URL}feedsubscription/${compId}`
      const gameUrl = `${import.meta.env.VITE_API_URL}feedsubscription/${gameId}`
      let feedDataShell: SubscriberInfo[] = []
      try {
        const compResponse = await fetch(compUrl, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
        if (compResponse && compResponse.ok) {
          const data: FeedSubscription[] = await compResponse.json()
          if (data.length) {
            feedDataShell = data.map(item => {
              return {
                subscriberId: item.subscriberId,
                subscriberName: item.subscriberName || 'No name',
                feedTypes: item.supportFeedTypes || ['No Feeds'],
                compSubscribed: true,
                matchSubscribed: false,
              }
            })
          }
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
      try {
        const gameResponse = await fetch(gameUrl, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
        if (gameResponse && gameResponse.ok) {
          const data: FeedSubscription[] = await gameResponse.json()
          if (data.length) {
            data.forEach(item => {
              // check if comp already added subscriber
              const subscriber = feedDataShell.findIndex(sub => sub.subscriberId === item.subscriberId)
              if (subscriber !== -1) {
                feedDataShell[subscriber] = { ...feedDataShell[subscriber], matchSubscribed: true }
              } else {
                feedDataShell.push({
                  subscriberId: item.subscriberId,
                  subscriberName: item.subscriberName || 'No name',
                  feedTypes: item.supportFeedTypes || ['No Feeds'],
                  compSubscribed: false,
                  matchSubscribed: true,
                })
              }
            })
          }
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
      setFeedData(feedDataShell)
    }

    if (autoUpdate && id) {
      await Promise.all([fetchConnectionData(id), fetchFeedData(id, matchCore?.competitionStage?.competition?.id)])
    }

    revalidator.revalidate()
  }, 10000)

  if (!matchCore && !matchAdvanced) {
    return (
      <NonIdealState
        title="No match found"
        description="We couldn't find a match with this ID:"
        iconColor="red.600"
        icon={['fad', 'triangle-exclamation']}
      >
        <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600" color="white">
          {id}
        </Code>
      </NonIdealState>
    )
  }

  const displayInning = showAdvancedData ? liveInningAdvanced : liveInningCore
  const displayMatch = showAdvancedData ? matchAdvanced : matchCore

  return (
    <>
      <VStack width="100%" spacing="0px">
        <Flex width="100%">
          <ScoreBox liveMonitoredMode game={displayMatch} />
        </Flex>
        <Box width="100%" margin="0" paddingLeft="1em" paddingRight="1em">
          <Flex flex={1} maxW="container.xl" width="100%" marginTop="1em">
            <SimpleGrid
              spacing={4}
              width="100%"
              templateColumns={isExtraSmallViewport ? 'auto minmax(0, 1fr)' : 'auto minmax(0, 1fr) minmax(0, 1fr)'}
            >
              <CoverageLevelIndicator
                coverageLevelId={matchCore.matchConfigs?.coverageLevelId || matchAdvanced.matchConfigs?.coverageLevelId}
                isPreview
              />
              <MatchConnectionsPreview match={displayMatch} connections={connectionData} />
              {!isExtraSmallViewport && <MatchFeedsPreview subscribers={feedData} matchId={id} />}
            </SimpleGrid>
          </Flex>

          <Last12Balls
            overs={showAdvancedData ? activeInningBallsAdvanced : activeInningBallsCore}
            overKeys={showAdvancedData ? activeInningBallKeysAdvanced : activeInningBallKeysCore}
            condensed={false}
            showMaxOvers={1}
            width="100%"
            bg="primary.700"
            borderTopLeftRadius="6px"
            borderTopRightRadius="6px"
            padding={2}
            marginTop={4}
          />

          <SimpleGrid
            columns={2}
            spacing={5}
            width="100%"
            border="1px solid"
            borderColor="primary.500"
            borderTop="none"
            borderBottomLeftRadius="6px"
            borderBottomRightRadius="6px"
            padding={5}
            paddingTop="1em"
            height="7.3em"
          >
            <VStack width="100%" alignItems="flex-start">
              <Heading size="sm" lineHeight="1.5">
                Batters
              </Heading>
              <UnorderedList styleType="none" marginLeft="0.5em" width="100%">
                {displayInning &&
                  getCurrentBatterPerformances(displayInning).map(perf => {
                    return (
                      <ListItem
                        key={`${id}_batters_${getPlayerDisplayName(displayMatch.matchTeams, perf.playerMp)}`}
                        width="100%"
                      >
                        <HStack justifyContent="space-between">
                          <EntityLink
                            entityType="people"
                            entityId={getPlayer(displayMatch.matchTeams, perf.playerMp)?.player.id}
                          >
                            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              {`${getPlayerDisplayName(displayMatch.matchTeams, perf.playerMp)}`}
                              {perf.onStrike && <Icon as={FontAwesomeIcon} icon={['fad', 'cricket-bat-ball']} ml={2} />}
                            </Text>
                          </EntityLink>

                          <Text whiteSpace="nowrap">{`${perf.runs} (${perf.balls})`}</Text>
                        </HStack>
                      </ListItem>
                    )
                  })}
              </UnorderedList>
            </VStack>

            <VStack width="100%" alignItems="flex-start">
              <Heading size="sm" lineHeight="1.5">
                Bowlers
              </Heading>
              <UnorderedList styleType="none" marginLeft="0.5em" width="100%">
                {displayInning &&
                  onStrikerOrdered(getCurrentBowlerPerformances(displayInning)).map(perf => {
                    return (
                      <ListItem
                        key={`${id}_bowler_${getPlayerDisplayName(displayMatch.matchTeams, perf.playerMp)}`}
                        width="100%"
                      >
                        <HStack justifyContent="space-between">
                          <EntityLink
                            entityType="people"
                            entityId={getPlayer(displayMatch.matchTeams, perf.playerMp)?.player.id}
                          >
                            <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              {getPlayerDisplayName(displayMatch.matchTeams, perf.playerMp)}
                              {perf.onStrike && <Icon as={FontAwesomeIcon} icon={['fad', 'person-running']} ml={2} />}
                            </Text>
                          </EntityLink>
                          <Text whiteSpace="nowrap">{`${perf.wickets}/${perf.runs} (${perf.overs})`}</Text>
                        </HStack>
                      </ListItem>
                    )
                  })}
              </UnorderedList>
            </VStack>
          </SimpleGrid>
        </Box>
        <Flex justifyContent="space-between" width={'100%'} key={`PreviewMatchFooter_${id}`} padding="1em">
          <Stack direction="row" alignItems="center" spacing={3}>
            <Switch
              variant="primary"
              onChange={() => setShowAdvancedData(!showAdvancedData)}
              isChecked={showAdvancedData}
              isDisabled={isCoreCoverageLevel}
            />
            <Text color={isCoreCoverageLevel ? theme.colors.primary['500'] : 'white'}>Show advanced data</Text>
          </Stack>
          <Button
            key={`OpenMatch_${id}`}
            mr={3}
            as={RouterLink}
            to={`/match-centre/${id}`}
            target="_blank"
            rel="noopener noreferrer"
            marginRight="0"
            variant="liveMonitorView"
          >
            Open Match Center
          </Button>
        </Flex>
      </VStack>
    </>
  )
}

export default LiveMonitoredGame
