import type { QueryClient } from '@tanstack/react-query'
import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import type { LoaderFunctionArgs } from 'react-router-dom'

import { ballInningsQuery } from '../api/get-ball-innings'
import { matchCentreMatchQuery } from '../api/get-match-centre-match'
import { matchIdsQuery } from '../api/get-match-ids'
import { useScoringMode } from './scoring-mode.context'

type DisplayIdType = 'clsp' | 'sds'

export type MatchLoaderData = Awaited<ReturnType<ReturnType<typeof matchLoader>>>

type MatchContextType = MatchLoaderData & {
  displayIdType: DisplayIdType
}
const MatchContext = createContext<MatchContextType | undefined>(undefined)

export const matchLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const bypassLookup = new URL(request.url).searchParams.get('bypassLookup') === 'true'

    const [advancedModeData, coreModeData, advancedModeBalls, coreModeBalls, matchIds] = await Promise.all([
      queryClient.ensureQueryData(matchCentreMatchQuery({ id: params.id || '', mode: 'advanced', bypassLookup })),
      queryClient.ensureQueryData(matchCentreMatchQuery({ id: params.id || '', mode: 'core', bypassLookup })),
      queryClient.ensureQueryData(ballInningsQuery({ id: params.id || '', mode: 'advanced' })),
      queryClient.ensureQueryData(ballInningsQuery({ id: params.id || '', mode: 'core' })),
      queryClient.ensureQueryData(matchIdsQuery({ id: params.id || '' })),
    ])

    return {
      advancedModeData,
      coreModeData,
      advancedModeBalls,
      coreModeBalls,
      matchIds,
    }
  }

type MatchProviderProps = {
  children: ReactNode
  match: MatchLoaderData
  displayIdType?: DisplayIdType
}

export function MatchProvider({ children, match, displayIdType = 'clsp' }: MatchProviderProps) {
  return <MatchContext.Provider value={{ ...match, displayIdType }}>{children}</MatchContext.Provider>
}

export function useMatch() {
  const scoringMode = useScoringMode()
  const { coreModeData, advancedModeData } = useContext(MatchContext) as MatchContextType // TODO: can we avoid this "as" ?
  return scoringMode === 'advanced' ? advancedModeData : coreModeData
}

export function useMatchIds() {
  const { matchIds, displayIdType } = useContext(MatchContext) as MatchContextType // TODO: can we avoid this "as" ?

  return {
    displayIdType,
    matchIds: new Map(Object.entries(matchIds)),
  }
}
