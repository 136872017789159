import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'

import type { ClspMode } from '../types'

const ScoringModeContext = createContext<ClspMode | undefined>(undefined)

type ScoringModeProviderProps = {
  children: ReactNode
  mode: ClspMode
}

export function ScoringModeProvider({ children, mode }: ScoringModeProviderProps) {
  return <ScoringModeContext.Provider value={mode}>{children}</ScoringModeContext.Provider>
}

export function useScoringMode() {
  const scoringMode = useContext(ScoringModeContext)
  return scoringMode || 'core'
}
