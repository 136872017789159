import { Box, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { orderBy } from 'lodash'

import { useMatchIds } from '../../contexts/match.context'
import { textCleaner } from '../../helpers/data'
import { getEnumKeyByEnumValue } from '../../helpers/match'
import { getPlayerDisplayName } from '../../helpers/player'
import { getStaffFullName } from '../../helpers/staff'
import { MatchStaffRoles } from '../../reference'
import { ActiveReason, type MatchTeam } from '../../types/match'
import { CheckGreen, XMarkRed } from '../../ui/colours'
import { EntityLink } from '../entity-link'

type SquadsListProps = {
  team?: MatchTeam
  condensed: boolean
}

export const SquadList = ({ team, condensed }: SquadsListProps) => {
  const { matchIds, displayIdType } = useMatchIds()

  if (!team) return null

  return (
    <>
      <Stack
        flex={1}
        spacing={3}
        border="1px solid"
        borderColor="primary.600"
        borderRadius="6px"
        py={3}
        px={4}
        maxWidth={condensed ? '35em' : undefined}
        overflowX="scroll"
      >
        <Text fontWeight="bold">{team.name}</Text>

        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Id</Th>
                <Th>Shirt No.</Th>
                <Th>Active Status</Th>
                <Th>Active Reason</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orderBy(team.matchPlayers, ['selectionNumber'], ['asc']).map(player => (
                <Tr key={`${player.id}_${team.id}`}>
                  <Td>
                    <EntityLink entityType="people" entityId={player.player.id}>
                      <div>
                        {getPlayerDisplayName([team], player.id)} {player.wicketKeeper && '(WK)'}{' '}
                        {player.captain && '(C)'}
                      </div>
                    </EntityLink>
                  </Td>
                  <Td>{displayIdType === 'sds' ? matchIds.get(player.player.id || '') : player.player.id}</Td>
                  <Td>{player.shirtNumber}</Td>
                  <Td>
                    <FontAwesomeIcon
                      icon={['fas', player.activeStatus ? 'check-circle' : 'circle-xmark']}
                      size="sm"
                      style={{
                        marginRight: '8px',
                        fontSize: '1em',
                        color: player.activeStatus ? CheckGreen : XMarkRed,
                      }}
                    />
                  </Td>
                  <Td>
                    {player.activeReasonId ? getEnumKeyByEnumValue(ActiveReason, player.activeReasonId.toString()) : ''}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {team.matchStaff && (
          <Box>
            {team.matchStaff.map(staff => (
              <Text key={staff.id} textStyle="boldLabel" fontSize="16px" textTransform="uppercase" marginBottom="5px">
                {getStaffFullName(staff)}{' '}
                <small>
                  ({staff.matchTeamRoleId !== null ? textCleaner(MatchStaffRoles[staff.matchTeamRoleId]) : 'Unknown'})
                </small>
              </Text>
            ))}
          </Box>
        )}
      </Stack>
    </>
  )
}
