import {
  Box,
  Button,
  ButtonGroup,
  Code,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { isNil, reverse } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { useLoaderData, useParams, useRevalidator, useSearchParams } from 'react-router-dom'
import { useInterval } from 'usehooks-ts'

import { ballInningsQuery } from '../../api/get-ball-innings'
import { matchCentreMatchQuery } from '../../api/get-match-centre-match'
import { matchIdsQuery } from '../../api/get-match-ids'
import { BallPanel } from '../../components/ball-panel'
import { CoverageLevelIndicator } from '../../components/coverage-level-indicator'
import { DownloadsPanel } from '../../components/downloads'
import { LivePanel } from '../../components/live-panel'
import MatchConditionsPanel from '../../components/match-conditions'
import { MatchConnections } from '../../components/match-connections'
import { MatchFeeds } from '../../components/match-feeds'
import { MatchNotes } from '../../components/match-notes'
import { ModeHeading } from '../../components/mode-heading'
import { ScoreBox } from '../../components/score-box'
import { Scorebook } from '../../components/scorebook'
import type { MatchLoaderData } from '../../contexts/match.context'
import { matchLoader, MatchProvider } from '../../contexts/match.context'
import { ScoringModeProvider } from '../../contexts/scoring-mode.context'
import { getTokens } from '../../helpers/auth'
import { confirmedBallsGrouped } from '../../helpers/ball'
import { idCleaner } from '../../helpers/data'
import { getInningsBattingTeamShortName } from '../../helpers/inning'
import {
  getActiveInning,
  getAllInningsInOrder,
  getAwayTeam,
  getHomeTeam,
  getInningByMatchOrder,
} from '../../helpers/match'
import { theme } from '../../theme'
import type { FeedSubscription, FeedType, MatchConnection, SubscriberInfo } from '../../types'
import type { Ball } from '../../types/ball'
import type { MatchDls } from '../../types/match'
import { CoverageLevelId } from '../../types/match'
import { NonIdealState } from '../../ui/non-ideal-state'

const tokens = getTokens()

export const loader = matchLoader

const Game = observer(() => {
  const { id } = useParams()
  const [connectionData, setConnectionData] = useState<MatchConnection>()
  const [feedData, setFeedData] = useState<SubscriberInfo[]>([])

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [activeTab, setActiveTab] = useState(1)
  const [ballJSON, setBallJSON] = useState('')
  const [autoUpdate, setAutoUpdate] = useState(
    !!(import.meta.env.VITE_ENV_AUTO_UPDATE && import.meta.env.VITE_ENV_AUTO_UPDATE !== 'false')
  )
  const [showAdvancedData, setShowAdvancedData] = useState(false)
  const [showSdsIds, setShowSdsIds] = useState(false)
  const matchLoaderData = useLoaderData() as MatchLoaderData
  const { advancedModeData, coreModeData, advancedModeBalls, coreModeBalls } = matchLoaderData

  const [searchParams, setSearchParams] = useSearchParams()
  const params = Object.fromEntries(searchParams)
  const bypassLookup = params.bypassLookup === 'true'

  const revalidator = useRevalidator()

  const { data: matchAdvanced } = useQuery({
    ...matchCentreMatchQuery({
      id: id || '',
      mode: advancedModeData.matchConfigs?.coverageLevelId === CoverageLevelId.POST_MATCH ? 'postMatch' : 'advanced',
      bypassLookup,
    }),
    initialData: advancedModeData,
    refetchInterval: autoUpdate ? 10000 : false,
    enabled: advancedModeData.matchConfigs?.coverageLevelId !== CoverageLevelId.CORE,
    refetchIntervalInBackground: true,
  })
  const { data: matchCore } = useQuery({
    ...matchCentreMatchQuery({ id: id || '', mode: 'core', bypassLookup }),
    initialData: coreModeData,
    refetchInterval: autoUpdate ? 10000 : false,
    enabled: advancedModeData.matchConfigs?.coverageLevelId !== CoverageLevelId.POST_MATCH,
    refetchIntervalInBackground: true,
  })
  const { data: matchIds } = useQuery({
    ...matchIdsQuery({ id: id || '' }),
  })
  const { data: ballInningsAdvanced } = useQuery({
    ...ballInningsQuery({ id: id || '', mode: 'advanced' }),
    initialData: advancedModeBalls,
    enabled: [CoverageLevelId.ADVANCED, CoverageLevelId.FIELDING, null, undefined].includes(
      advancedModeData.matchConfigs?.coverageLevelId
    ),
    refetchInterval: autoUpdate ? 10000 : false,
    refetchIntervalInBackground: true,
  })
  const { data: ballInningsCore } = useQuery({
    ...ballInningsQuery({ id: id || '', mode: 'core' }),
    initialData: coreModeBalls,
    enabled: advancedModeData.matchConfigs?.coverageLevelId !== CoverageLevelId.POST_MATCH,
    refetchInterval: autoUpdate ? 10000 : false,
    refetchIntervalInBackground: true,
  })

  // selectedInning for each mode
  const selectedInningCore = getInningByMatchOrder(matchCore.matchTeams, activeTab)
  const selectedInningAdvanced = getInningByMatchOrder(matchAdvanced.matchTeams, activeTab)

  // liveInning for each mode
  const liveInningCore = getActiveInning(matchCore)
  const liveInningAdvanced = getActiveInning(matchAdvanced)

  // balls for each mode
  const ballsCore =
    ballInningsCore && ballInningsCore.find
      ? confirmedBallsGrouped(ballInningsCore.find(inning => inning.inningsId === selectedInningCore?.id)?.balls)
      : undefined

  const ballsAdvanced =
    ballInningsAdvanced && ballInningsAdvanced.find
      ? confirmedBallsGrouped(
          ballInningsAdvanced.find(inning => inning.inningsId === selectedInningAdvanced?.id)?.balls
        )
      : undefined

  // activeInningBalls for each mode
  const activeInningBallsCore =
    ballInningsCore && ballInningsCore.find
      ? confirmedBallsGrouped(ballInningsCore.find(inning => inning.inningsId === liveInningCore?.id)?.balls)
      : undefined

  const activeInningBallsAdvanced =
    ballInningsAdvanced && ballInningsAdvanced.find
      ? confirmedBallsGrouped(ballInningsAdvanced.find(inning => inning.inningsId === liveInningAdvanced?.id)?.balls)
      : undefined

  // ballKeys for each mode
  const ballKeysCore = ballsCore ? reverse(Object.keys(ballsCore).map(key => parseInt(key))) : []
  const ballKeysAdvanced = ballsAdvanced ? reverse(Object.keys(ballsAdvanced).map(key => parseInt(key))) : []

  // activeInningBallKeys for each mode
  const activeInningBallKeysCore = activeInningBallsCore
    ? reverse(Object.keys(activeInningBallsCore).map(key => parseInt(key)))
    : []
  const activeInningBallKeysAdvanced = activeInningBallsAdvanced
    ? reverse(Object.keys(activeInningBallsAdvanced).map(key => parseInt(key)))
    : []

  // Init coverage level check vars
  const isCoreCoverageLevel =
    !isNil(matchCore.matchConfigs) &&
    !isNil(matchCore.matchConfigs.coverageLevelId) &&
    matchCore.matchConfigs?.coverageLevelId <= CoverageLevelId.CORE

  const isPostMatchCoverageLevel =
    !isNil(matchAdvanced.matchConfigs) &&
    !isNil(matchAdvanced.matchConfigs?.coverageLevelId) &&
    matchAdvanced.matchConfigs?.coverageLevelId === CoverageLevelId.POST_MATCH

  useEffect(() => {
    // check coverage level
    if (isPostMatchCoverageLevel) {
      setShowAdvancedData(true)
    }

    // update page title
    const activeInning = getActiveInning(matchCore) || getActiveInning(matchAdvanced)
    if (activeInning) {
      const battingTeam = matchCore.matchTeams?.find(team => team.id === activeInning.battingTeamId)
      document.title = `${battingTeam?.shortName || 'Unknown team'}
       ${activeInning.progressiveScores.runs}/${activeInning.progressiveScores.wickets}
        (${activeInning.progressiveScores.oversBowled})`
    } else {
      document.title = `${getHomeTeam(matchCore.matchTeams)?.name} vs ${getAwayTeam(matchCore.matchTeams)?.name}`
    }
  }, [matchCore, matchAdvanced])

  useInterval(async () => {
    async function fetchConnectionData(id: string) {
      const url = `${import.meta.env.VITE_API_URL}matchConnections/${id}`
      const response = await fetch(url, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
      if (response && response.ok) {
        const data = await response.json()
        setConnectionData(data)
      }
    }
    async function fetchFeedData(gameId: string, compId: string | undefined) {
      const compUrl = `${import.meta.env.VITE_API_URL}feedsubscription/${compId}`
      const gameUrl = `${import.meta.env.VITE_API_URL}feedsubscription/${gameId}`
      let feedDataShell: SubscriberInfo[] = []
      try {
        const compResponse = await fetch(compUrl, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
        if (compResponse && compResponse.ok) {
          const data: FeedSubscription[] = await compResponse.json()
          if (data.length) {
            feedDataShell = data.map(item => {
              return {
                subscriberId: item.subscriberId,
                subscriberName: item.subscriberName || 'No name',
                feedTypes: item.supportFeedTypes || ['No Feeds'],
                compSubscribed: true,
                matchSubscribed: false,
              }
            })
          }
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
      try {
        const gameResponse = await fetch(gameUrl, { headers: { Authorization: `Bearer ${tokens?.accessToken}` } })
        if (gameResponse && gameResponse.ok) {
          const data: FeedSubscription[] = await gameResponse.json()
          if (data.length) {
            data.forEach(item => {
              // check if comp already added subscriber
              const subscriber = feedDataShell.findIndex(sub => sub.subscriberId === item.subscriberId)
              if (subscriber !== -1) {
                feedDataShell[subscriber] = { ...feedDataShell[subscriber], matchSubscribed: true }
              } else {
                feedDataShell.push({
                  subscriberId: item.subscriberId,
                  subscriberName: item.subscriberName || 'No name',
                  feedTypes: item.supportFeedTypes || ['No Feeds'],
                  compSubscribed: false,
                  matchSubscribed: true,
                })
              }
            })
          }
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
      setFeedData(feedDataShell)
    }

    if (autoUpdate && id) {
      await Promise.all([fetchConnectionData(id), fetchFeedData(id, matchCore?.competitionStage?.competition?.id)])
    }

    revalidator.revalidate()
  }, 10000)

  const setModalAndBall = (data: Ball | MatchDls | null) => {
    const ball = toJS(idCleaner([data], 'BALL'))
    setBallJSON(JSON.stringify(ball[0], null, 2))
    onOpen()
  }

  const handleAutoUpdateSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    setAutoUpdate(event.target.checked)
  }

  const setBypassLookup = (enabled: boolean) => {
    const params = Object.fromEntries(searchParams)
    if (enabled) {
      params.bypassLookup = 'true'
    } else {
      delete params.bypassLookup
    }
    setSearchParams(params)
  }

  const handleSkipCacheSwitch = (event: ChangeEvent<HTMLInputElement>) => setBypassLookup(event.target.checked)

  const handleAdvancedDataSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    setShowAdvancedData(event.target.checked)
    if (event.target.checked && bypassLookup) setBypassLookup(false)
  }

  const handleShowSdsIdsSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSdsIds(event.target.checked)
  }

  if (!id) {
    return (
      <NonIdealState
        title="No match ID provided"
        description="Please provide a match ID in the URL"
        iconColor="red.600"
        icon={['fad', 'triangle-exclamation']}
      />
    )
  }

  if (!matchCore) {
    return (
      <NonIdealState
        title="No match found"
        description="We couldn't find a match with this ID:"
        iconColor="red.600"
        icon={['fad', 'triangle-exclamation']}
      >
        <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600" color="white">
          {id}
        </Code>
      </NonIdealState>
    )
  }

  return (
    <MatchProvider match={matchLoaderData} displayIdType={showSdsIds ? 'sds' : 'clsp'}>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>JSON Payload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box background="primary.600" my={4} p={4} borderRadius="6px">
              <Text as="pre" fontSize="12px" whiteSpace="pre-wrap">
                {ballJSON}
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex direction="column" w="100%">
        <Stack direction="row" w="100%" spacing={3}>
          {!isNil(matchCore.matchConfigs) && !isNil(matchCore.matchConfigs.coverageLevelId) && (
            <CoverageLevelIndicator coverageLevelId={matchCore.matchConfigs.coverageLevelId} />
          )}
          <Flex
            flex={1}
            direction={{ base: 'column', xl: 'row' }}
            alignItems={{ base: 'flex-start', xl: 'center' }}
            justifyContent={{ base: 'initial', xl: 'space-between' }}
            borderRadius="6px"
            px={4}
            py={3}
            bg="primary.600"
          >
            <Stack direction="row" spacing={8} mr={{ base: 0, xl: 8 }} mb={{ base: 4, xl: 0 }}>
              <Stack direction="row" flex={1} alignItems="center" spacing={3}>
                <Text color="white">Auto Update</Text>
                <Switch variant="primary" onChange={handleAutoUpdateSwitch} isChecked={autoUpdate} />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Text color="white">Data direct from DB</Text>
                <Switch variant="primary" onChange={handleSkipCacheSwitch} isChecked={bypassLookup} />
              </Stack>
              <Stack direction="column" justify="center" align="flex-start" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Text
                    color={
                      !isNil(matchCore.matchConfigs) &&
                      !isNil(matchCore.matchConfigs.coverageLevelId) &&
                      matchCore.matchConfigs.coverageLevelId <= 1
                        ? theme.colors.primary['500']
                        : 'white'
                    }
                  >
                    Show advanced data
                  </Text>
                  <Switch
                    variant="primary"
                    onChange={handleAdvancedDataSwitch}
                    isChecked={showAdvancedData}
                    isDisabled={isCoreCoverageLevel || isPostMatchCoverageLevel}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Text color="white">SDS IDs</Text>
                <Switch variant="primary" onChange={handleShowSdsIdsSwitch} isChecked={showSdsIds} />
              </Stack>
            </Stack>
          </Flex>
        </Stack>
        <Stack direction="row" spacing={6} mt={6}>
          <Flex flex={1} bg="primary.600" px={4} py={3} borderRadius="6px">
            <MatchConnections
              match={isPostMatchCoverageLevel ? matchAdvanced : matchCore}
              connections={connectionData}
            />
          </Flex>
          <Flex flex={1} bg="primary.600" px={4} py={3} borderRadius="6px">
            <MatchFeeds matchId={matchCore.id || matchAdvanced.id} bypassLookup={bypassLookup} subscribers={feedData} />
          </Flex>
        </Stack>
        <ScoreBox game={showAdvancedData ? matchAdvanced : matchCore} />
        <Tabs isFitted variant="enclosed" mt={6}>
          <TabList background="primary.500" borderTopLeftRadius="6px" borderTopRightRadius="6px">
            {!isPostMatchCoverageLevel && (liveInningCore || liveInningAdvanced) && (
              <Tab _selected={{ bg: 'green.400' }}>Live</Tab>
            )}
            <Tab _selected={{ bg: 'green.400' }}>Scorecard</Tab>
            {!isPostMatchCoverageLevel && <Tab _selected={{ bg: 'green.400' }}>Ball-by-ball</Tab>}
            <Tab _selected={{ bg: 'green.400' }}>Conditions</Tab>
            <Tab _selected={{ bg: 'green.400' }}>Downloads</Tab>
          </TabList>
          <TabPanels
            p={4}
            border="1px solid"
            borderColor="primary.500"
            borderBottomLeftRadius="6px"
            borderBottomRightRadius="6px"
          >
            {/** live */}
            {!isPostMatchCoverageLevel && (liveInningCore || liveInningAdvanced) && (
              <TabPanel p={0}>
                <Stack direction={{ base: 'column', lg: 'row' }} spacing={4}>
                  {liveInningCore && !isPostMatchCoverageLevel && (
                    <ScoringModeProvider mode="core">
                      <Flex
                        w={{ base: '100%', lg: showAdvancedData ? 'calc(50% - 20px)' : '100%' }}
                        flex={1}
                        direction="column"
                      >
                        {showAdvancedData && <ModeHeading />}
                        <LivePanel
                          activeInning={liveInningCore}
                          balls={activeInningBallsCore}
                          ballKeys={activeInningBallKeysCore}
                          condensed={showAdvancedData}
                        />
                      </Flex>
                    </ScoringModeProvider>
                  )}
                  {showAdvancedData && liveInningAdvanced && (
                    <ScoringModeProvider mode="advanced">
                      <Flex
                        w={{ base: '100%', lg: showAdvancedData ? 'calc(50% - 20px)' : '100%' }}
                        flex={1}
                        direction="column"
                      >
                        {!isPostMatchCoverageLevel && <ModeHeading />}
                        <LivePanel
                          activeInning={liveInningAdvanced}
                          balls={activeInningBallsAdvanced}
                          ballKeys={activeInningBallKeysAdvanced}
                          condensed={!isPostMatchCoverageLevel}
                        />
                      </Flex>
                    </ScoringModeProvider>
                  )}
                </Stack>
              </TabPanel>
            )}
            {/** scorecard */}
            <TabPanel p={0}>
              <Flex w="100%" justifyContent="center" alignItems="center">
                {/** Overs preview */}
                <ButtonGroup size="sm" isAttached>
                  {getAllInningsInOrder(isPostMatchCoverageLevel ? matchAdvanced : matchCore)?.map(inning => {
                    return (
                      <Button
                        key={inning.inningsMatchOrder}
                        mr="-px"
                        fontSize="16px"
                        paddingX="30px"
                        isActive={inning.inningsMatchOrder === activeTab}
                        onClick={() => setActiveTab(inning.inningsMatchOrder)}
                        _active={{
                          bg: 'green.400',
                          color: 'white',
                        }}
                      >
                        {getInningsBattingTeamShortName(
                          inning,
                          isPostMatchCoverageLevel ? matchAdvanced.matchTeams : matchCore.matchTeams
                        )}{' '}
                        {inning.progressiveScores.runs}/{inning.progressiveScores.wickets}
                      </Button>
                    )
                  })}
                </ButtonGroup>
              </Flex>
              <Flex direction="column" mt={4}>
                <Stack direction="row" spacing={6}>
                  {!isPostMatchCoverageLevel && matchCore && (
                    <ScoringModeProvider mode="core">
                      <Flex flex={1} direction="column">
                        {showAdvancedData && <ModeHeading />}
                        <Scorebook
                          game={matchCore}
                          selectedInning={selectedInningCore}
                          setModalAndBall={setModalAndBall}
                          condensed={showAdvancedData}
                        />
                      </Flex>
                    </ScoringModeProvider>
                  )}
                  {showAdvancedData && matchAdvanced && (
                    <ScoringModeProvider mode="advanced">
                      <Flex flex={1} w="calc(50% - 10px)" direction="column" mx="5px">
                        {!isPostMatchCoverageLevel && <ModeHeading />}
                        <Scorebook
                          game={matchAdvanced}
                          selectedInning={selectedInningAdvanced}
                          setModalAndBall={setModalAndBall}
                          condensed={showAdvancedData && !isCoreCoverageLevel}
                        />
                      </Flex>
                    </ScoringModeProvider>
                  )}
                </Stack>
                {matchCore.matchNotes && <MatchNotes notes={matchCore.matchNotes} />}
              </Flex>
            </TabPanel>
            {/** Ball-by-ball */}
            {!isPostMatchCoverageLevel && (
              <TabPanel p={0}>
                <BallPanel
                  activeInning={isPostMatchCoverageLevel ? selectedInningAdvanced : selectedInningCore}
                  activeTab={activeTab}
                  ballsCore={ballsCore}
                  ballKeysCore={ballKeysCore}
                  ballsAdvanced={ballsAdvanced}
                  ballKeysAdvanced={ballKeysAdvanced}
                  game={isPostMatchCoverageLevel ? matchAdvanced : matchCore}
                  setActiveTab={setActiveTab}
                  setModalAndBall={setModalAndBall}
                  bypassLookup={bypassLookup}
                  showAdvancedData={showAdvancedData}
                  isPostMatchCoverageLevel={isPostMatchCoverageLevel}
                />
              </TabPanel>
            )}
            {/** Conditions */}
            <TabPanel p={0}>
              <Stack direction="row" spacing={4}>
                {!isPostMatchCoverageLevel && matchCore && (
                  <ScoringModeProvider mode="core">
                    <Flex flex={1} direction="column">
                      {showAdvancedData && <ModeHeading />}
                      <MatchConditionsPanel game={matchCore} />
                    </Flex>
                  </ScoringModeProvider>
                )}
                {showAdvancedData && matchAdvanced && (
                  <ScoringModeProvider mode="advanced">
                    <Flex flex={1} direction="column">
                      {!isPostMatchCoverageLevel && <ModeHeading />}
                      <MatchConditionsPanel game={matchAdvanced} />
                    </Flex>
                  </ScoringModeProvider>
                )}
              </Stack>
            </TabPanel>
            {/** Downloads */}
            <TabPanel p={0}>
              <DownloadsPanel
                gameId={id}
                compId={matchCore.competitionStage?.competition?.id}
                isPostMatchCoverageLevel={isPostMatchCoverageLevel}
                showAdvancedData={showAdvancedData}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </MatchProvider>
  )
})

export default Game
