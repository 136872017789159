import type { CLSToSDSData } from '@clsplus/api-types/api-admin'

import { getTokens } from '../../helpers/auth'

type GetMatchIdsParams = {
  id: string
}

function getMatchIds(params: GetMatchIdsParams): Promise<CLSToSDSData> {
  const tokens = getTokens()
  const { id } = params

  return fetch(`${import.meta.env.VITE_API_URL}admin/id-lookup/${id}`, {
    headers: { Authorization: `Bearer ${tokens?.accessToken}` },
  })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error)
      }

      return data
    })
}

export const matchIdsQuery = (params: GetMatchIdsParams) => ({
  queryKey: ['match-ids', params],
  queryFn: () => getMatchIds(params),
})
