import type { IconProp } from '@fortawesome/fontawesome-svg-core'

import { MCRequiredLevelEnum } from './auth'

type NavGroup = {
  label?: string
  items: NavItem[]
}

type NavItem = {
  href: string
  icon: IconProp
  label: string
  requiredAccess: MCRequiredLevelEnum
}

export const getNavGroups = () => {
  const showEntityMerge = import.meta.env.VITE_ENTITY_MERGE_ENABLED === 'true'
  const showUserManagement = import.meta.env.VITE_USER_MANAGEMENT_ENABLED === 'true'

  const navGroups: NavGroup[] = [
    {
      label: 'Live matches',
      items: [
        {
          href: '/live-monitoring',
          label: 'Live Monitoring',
          icon: ['fad', 'circle-dot'],
          requiredAccess: MCRequiredLevelEnum.locked,
        },
        {
          href: '/match-centre',
          label: 'Match centre',
          icon: ['fad', 'cricket-bat-ball'],
          requiredAccess: MCRequiredLevelEnum.locked,
        },
      ],
    },
    {
      label: 'Feeds & subscriptions',

      items: [
        {
          label: 'Competition feeds',
          href: '/competition-feeds',
          icon: ['fad', 'up-from-bracket'],
          requiredAccess: MCRequiredLevelEnum['cpa-admins'],
        },
        {
          label: 'Subscriptions',
          href: '/subscriptions',
          icon: ['fad', 'down-to-bracket'],
          requiredAccess: MCRequiredLevelEnum['cpa-admins'],
        },
      ],
    },
    {
      label: 'Data import',
      items: [
        {
          label: 'Competition sync',
          href: '/competition-sync',
          icon: ['fad', 'rotate'],
          requiredAccess: MCRequiredLevelEnum['clsp-monitoring-users'],
        },
        // @ts-ignore
        ...(showEntityMerge
          ? [
              {
                label: 'Entity merge',
                href: '/merge-requests',
                icon: ['fad', 'merge'],
                requiredAccess: MCRequiredLevelEnum['cpa-admins'],
              },
            ]
          : []),
      ],
    },
    {
      label: 'Entity management',
      items: [
        {
          label: 'Competitions',
          href: '/competitions',
          icon: ['fad', 'trophy'],
          requiredAccess: MCRequiredLevelEnum['cpa-admins'],
        },
        {
          label: 'Matches',
          href: '/matches',
          icon: ['fad', 'calendar-days'],
          requiredAccess: MCRequiredLevelEnum['cpa-admins'],
        },
        {
          label: 'Organisations',
          href: '/organisations',
          icon: ['fad', 'people-roof'],
          requiredAccess: MCRequiredLevelEnum['cpa-admins'],
        },
        {
          label: 'People',
          href: '/people',
          icon: ['fad', 'user-group'],
          requiredAccess: MCRequiredLevelEnum['cpa-admins'],
        },
        {
          label: 'Teams',
          href: '/teams',
          icon: ['fad', 'shield-halved'],
          requiredAccess: MCRequiredLevelEnum['cpa-admins'],
        },
        {
          label: 'Venues',
          href: '/venues',
          icon: ['fad', 'location-dot'],
          requiredAccess: MCRequiredLevelEnum['cpa-admins'],
        },
      ],
    },
  ]

  if (showUserManagement) {
    navGroups.push({
      label: 'Admin',

      items: [
        {
          label: 'Users',
          href: '/users',
          icon: ['fad', 'people-roof'],
          requiredAccess: MCRequiredLevelEnum['clsp-superadmin'],
        },
      ],
    })
  }

  return navGroups
}
