import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import type { RowSelectionState } from '@tanstack/react-table'
import { useRevalidator } from 'react-router-dom'

import { matchCentreMatchesQuery } from '../../api/get-match-centre-matches'
import { formatShortDate } from '../../helpers/datetime'
import {
  addLiveMonitoredMatchId,
  clearLiveMonitoredMatchIds,
  getLiveMonitoredMatchIds,
  removeLiveMonitoredMatchId,
} from '../../helpers/liveMonitoringStorage'
import { Table } from '../../ui/table'

const maxSelectionSize = 6

// Query dates
const startDate = new Date()
startDate.setDate(startDate.getDate() - 6)
const endDate = new Date()
endDate.setDate(endDate.getDate() + 1)
const queryStartDate = new Date(`${formatShortDate(startDate)} 00:00:00`).toISOString()
const queryEndDate = new Date(`${formatShortDate(endDate)} 23:59:59`).toISOString()

const getInitSelected = (
  tableData: { id: string; match: string; venue: string }[],
  liveMonitoredMatchIds: Set<string>
): RowSelectionState => {
  const selectedState: RowSelectionState = {}
  for (const [index, match] of tableData.entries()) {
    if (liveMonitoredMatchIds.has(match.id)) {
      selectedState[index] = true
    }
  }
  return selectedState
}

export const LiveMonitoringAddMatchModal = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const liveMonitoredMatchIds = getLiveMonitoredMatchIds()

  const { data: matchList, isLoading } = useQuery(
    matchCentreMatchesQuery({
      isLive: true,
      startdate: queryStartDate,
      enddate: queryEndDate,
    })
  )

  const revalidator = useRevalidator()

  let tableData: { id: string; match: string; venue: string }[] | undefined = [{ id: '', match: '', venue: '' }]

  if (matchList) {
    tableData = []
    for (const match of matchList) {
      tableData.push({
        id: match.id || '',
        match: match.title || '',
        venue: match.venue?.name || '',
      })
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          clearLiveMonitoredMatchIds()
          revalidator.revalidate()
        }}
      >
        Clear All
      </Button>

      <Button variant="primary" onClick={onOpen}>
        Add Matches
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader backgroundColor={'green.400'} borderTopLeftRadius="5px" borderTopRightRadius="5px">
            <VStack direction="row" align="flex-start">
              <Text color="white" as="b" fontSize="0.8rem" textTransform="uppercase">
                Live Matches
              </Text>
              <Stack direction="row" spacing={3} padding={0} align="flex-end" marginTop={0}>
                <Text color="white" as="b" fontSize="2rem" textTransform="uppercase">
                  {matchList?.length}
                </Text>
                <Text color="white" as="i" fontWeight="normal" fontSize="0.8rem" paddingBottom="0.6em">
                  Select up to a maximum of {maxSelectionSize} matches
                </Text>
              </Stack>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <Table
                  columns={[
                    { accessorKey: 'id', header: 'ID' },
                    { accessorKey: 'match', header: 'Match' },
                    { accessorKey: 'venue', header: 'Venue' },
                  ]}
                  data={tableData}
                  enableRowSelection
                  selectionValueId="id"
                  maxSelectionSize={maxSelectionSize}
                  initialRowSelectionState={getInitSelected(tableData, liveMonitoredMatchIds)}
                  selectionChange={val => {
                    const strVal = val !== undefined ? String(val) : val

                    if (strVal) {
                      if (liveMonitoredMatchIds.has(strVal)) {
                        removeLiveMonitoredMatchId(strVal)
                      } else {
                        addLiveMonitoredMatchId(strVal)
                      }

                      // use reValidator to force state reload https://github.com/remix-run/react-router/discussions/10381#discussioncomment-5713606
                      revalidator.revalidate()
                    }
                  }}
                ></Table>
              </>
            )}
          </ModalBody>
          <ModalFooter borderTopColor={'whiteAlpha.100'} borderTopWidth={'2px'}>
            <Flex justifyContent="space-between" width={'100%'} alignSelf="center">
              <Stack direction="row" spacing={1} padding={0} align="flex-start" alignSelf="center">
                <Text alignSelf="flex-start" fontWeight="semibold">
                  {liveMonitoredMatchIds.size}
                </Text>
                <Text alignSelf="flex-start">items selected</Text>
              </Stack>

              <Button mr={3} onClick={onClose}>
                Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
