import { Heading, Stack, Wrap, WrapItem } from '@chakra-ui/react'

import { getAwayTeam, getHomeTeam } from '../../helpers/match'
import type { Match } from '../../types/match'
import { SquadList } from '../squad-list'

type SquadsProps = {
  game: Match
  condensed: boolean
}

export const Squads = ({ game, condensed }: SquadsProps) => {
  const homeTeam = getHomeTeam(game.matchTeams)
  const awayTeam = getAwayTeam(game.matchTeams)
  return (
    <Stack spacing={3}>
      <Heading as="h3" size="md">
        Squads
      </Heading>
      <Wrap>
        <WrapItem flex={'1 0 50%'}>{homeTeam && <SquadList team={homeTeam} condensed={condensed} />}</WrapItem>
        <WrapItem flex={'1 0 50%'}>{awayTeam && <SquadList team={awayTeam} condensed={condensed} />}</WrapItem>
      </Wrap>
    </Stack>
  )
}
