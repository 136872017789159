import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack } from '@chakra-ui/react'
import type { CreateUserRequest } from '@clsplus/api-types/api-admin'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useNavigate, useRevalidator } from 'react-router-dom'
import * as z from 'zod'

import { createUserDetails } from '../../../api/create-user-request'

const CreateUserSchema = z.object({
  email: z.string().email({ message: 'Please enter a Email' }),
})

type UserCreateFormData = z.infer<typeof CreateUserSchema>

export default function CreateUser() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const revalidator = useRevalidator()

  const createUserMutation = useMutation({
    mutationFn: (formData: CreateUserRequest) => createUserDetails(formData),
    onSuccess: async data => {
      const userId = data.user?.id
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['users'] }),
        queryClient.invalidateQueries({ queryKey: ['users/new'] }),
      ])
      revalidator.revalidate()
      navigate(`/users/${userId}`)
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<UserCreateFormData>({
    defaultValues: {
      email: undefined,
    },
    resolver: zodResolver(CreateUserSchema),
  })

  const handleSubmitClick = (formData: UserCreateFormData) => {
    createUserMutation.mutate(formData)
  }

  return (
    <Flex direction="column" w="100%">
      <Heading as="h2" size="lg">
        New User
      </Heading>
      <Stack
        as="form"
        noValidate
        id="new-user"
        spacing={4}
        maxW="container.sm"
        mt={8}
        onSubmit={handleSubmit(handleSubmitClick)}
      >
        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <Input {...register('email')} />
          {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={4} mt={8}>
        <Button
          type="submit"
          form="new-user"
          variant="primary"
          isDisabled={!isDirty}
          isLoading={createUserMutation.isLoading}
        >
          Save changes
        </Button>
        <Button variant="secondary" onClick={() => navigate(`/users/`)} isDisabled={createUserMutation.isLoading}>
          Cancel
        </Button>
      </Stack>
    </Flex>
  )
}
