import {
  Box,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { HasMCAccess, MCRequiredLevelEnum } from '../../helpers/auth'
import { useSendFeeds } from '../../helpers/useSendFeeds'
import type { MatchFeedsPreviewProps, MatchFeedsProps } from '../../types'

export const MatchFeedsPreview = ({ subscribers, matchId }: MatchFeedsPreviewProps) => {
  const isMediumViewport = useBreakpointValue({ base: false, md: true, lg: true, xl: false })
  return (
    <Box backgroundColor="primary.500" borderRadius="6px" padding={2} alignSelf="center" height="3em">
      <HStack alignItems="center">
        {isMediumViewport && (
          <Heading as="h3" size="sm" textAlign="center" marginRight="1em">
            Feeds
          </Heading>
        )}
        <Tag
          key={`feed-preview-${matchId}`}
          size="sm"
          background={subscribers.length > 0 ? 'green.400' : 'primary.700'}
          borderRadius="full"
          width="100%"
          textAlign="center"
        >
          <TagLabel
            fontSize="sm"
            fontWeight="normal"
            padding="0.5em"
            width="100%"
            textAlign="center"
            whiteSpace="nowrap"
            overflow="hidden"
          >{`${subscribers.length} subscribers`}</TagLabel>
        </Tag>
      </HStack>
    </Box>
  )
}

export const MatchFeeds = ({ matchId, bypassLookup, subscribers }: MatchFeedsProps) => {
  const { sendFeed, isSending } = useSendFeeds(matchId, bypassLookup)

  return (
    <Box width="100%" position="relative">
      <Heading as="h2" size="md" textAlign="center">
        Feeds
      </Heading>
      {subscribers.length > 0 && (
        <HasMCAccess minLevel={MCRequiredLevelEnum['cpa-admins']}>
          <Box position="absolute" top={0} right={0}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<Icon as={FontAwesomeIcon} icon={['fad', 'up-from-bracket']} color="white" />}
                variant="primary"
                size="xs"
                isDisabled={isSending}
              />
              <MenuList>
                <MenuItem onClick={() => sendFeed('match')}>Send Match Feed</MenuItem>
                <MenuItem onClick={() => sendFeed('allBall')}>Send All Ball Feed</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HasMCAccess>
      )}

      <Grid templateColumns="repeat(3, 1fr)" gap={2} mt={3}>
        {subscribers?.map(feed => (
          <Box w="100%" key={feed.subscriberId}>
            <Tooltip label={feed.feedTypes.join(', ')} aria-label="A tooltip" placement="top">
              <Box borderRadius="6px" bg="primary.700" p={2}>
                <Heading size="sm" as="h4" textAlign="center">
                  {feed.subscriberName}
                </Heading>
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme={feed.matchSubscribed ? 'green' : 'red'}
                  my={2}
                >
                  <Flex direction="row" alignItems="center">
                    <FontAwesomeIcon
                      icon={['fas', feed.matchSubscribed ? 'check-circle' : 'exclamation-circle']}
                      size="sm"
                      style={{ marginRight: '8px', fontSize: '12px' }}
                    />
                    <TagLabel>Match</TagLabel>
                  </Flex>
                </Tag>
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme={feed.compSubscribed ? 'green' : 'red'}
                  marginBottom="3px"
                >
                  <Flex direction="row" alignItems="center">
                    <FontAwesomeIcon
                      icon={['fas', feed.compSubscribed ? 'check-circle' : 'exclamation-circle']}
                      size="sm"
                      style={{ marginRight: '8px', fontSize: '12px' }}
                    />
                    <TagLabel>Competition</TagLabel>
                  </Flex>
                </Tag>
              </Box>
            </Tooltip>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}
