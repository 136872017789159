import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { orderBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import type { CellProps, Column } from 'react-table'
import { useTable } from 'react-table'

import { useMatch } from '../../contexts/match.context'
import { getBattingPartnershipMinutes } from '../../helpers/match'
import { getPlayerShortName } from '../../helpers/player'
import type { BattingPartnership, MatchPlayer, PartnershipPlayer } from '../../types/match'
import { EntityLink } from '../entity-link'

type PartnershipCardProps = {
  partnership: BattingPartnership
  teamPlayers?: MatchPlayer[]
  condensed: boolean
}

const statusMap = (value: number) => {
  switch (value) {
    case 0: {
      return (
        <Text as="span" paddingLeft="2px" fontSize="lg">
          *
        </Text>
      )
    }
    case 1: {
      return (
        <Text as="span" paddingLeft="5px" fontSize="md">
          out
        </Text>
      )
    }
    case 2:
    default: {
      return (
        <Text as="span" paddingLeft="5px" fontSize="md">
          retired
        </Text>
      )
    }
  }
}

export const PartnershipCard = ({ partnership, teamPlayers, condensed }: PartnershipCardProps) => {
  const match = useMatch()

  const [matchInfo, setMatchInfo] = useState(match)

  useEffect(() => {
    setMatchInfo(match)
  }, [match])

  const data = orderBy(partnership.partnershipPlayers, 'battingOrder', 'asc')

  const columns: Column<PartnershipPlayer>[] = useMemo(
    () => [
      {
        id: 'batterNames',
        accessor: (row: PartnershipPlayer) => {
          const player = teamPlayers?.find(player => player.id === row.playerMp)
          return player ? getPlayerShortName(player) : ''
        },
        Header: '',
        Cell: (props: CellProps<PartnershipPlayer, string>) => {
          const curMp = teamPlayers?.find(player => player.id === props.row.original.playerMp)
          return (
            <EntityLink entityType="people" entityId={curMp?.player.id} idDisplay="popover" popoverPlacement="right">
              <Text fontSize="md">
                {props.cell.value}
                <Text as="span" fontSize="sm" color="primary.300">
                  {statusMap(props.row.original.status)}
                </Text>
              </Text>
            </EntityLink>
          )
        },
      },
      {
        accessor: 'runs',
        Header: 'R',
      },
      {
        accessor: 'balls',
        Header: 'B',
      },
      ...(!condensed
        ? ([
            {
              accessor: 'strikeRate',
              Header: 'SR',
            },
            {
              accessor: 'dots',
              Header: '\u2022',
            },
            {
              accessor: 'ones',
              Header: '1',
            },
            {
              accessor: 'twos',
              Header: '2',
            },
            {
              accessor: 'threes',
              Header: '3',
            },
            {
              accessor: 'fours',
              Header: '4',
            },
            {
              accessor: 'sixes',
              Header: '6',
            },
          ] as const)
        : []),
    ],
    [condensed, teamPlayers]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })
  return (
    <Box border="1px solid" borderColor="primary.600" borderRadius="6px" p={2}>
      <Flex alignItems="center">
        <Box flex={1}>
          <Text fontWeight="bold">Wicket #{partnership.wicketNumber}</Text>
        </Box>
        <Box flex={1}>
          <Text fontSize="sm" fontWeight="medium" textAlign="right">
            {partnership.totalRuns} runs - {partnership.balls} balls
          </Text>
        </Box>
      </Flex>
      <Flex justify="space-between">
        <Text fontSize="sm">Start: {partnership.start}</Text>
        <Text fontSize="sm">End: {partnership.end || '-'}</Text>
        <Text fontSize="sm">Minutes: {getBattingPartnershipMinutes(partnership, matchInfo)}</Text>
      </Flex>

      <Table {...getTableProps()} mt={2}>
        <Thead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <Tr {...headerGroup.getHeaderGroupProps()} bg="primary.600">
              {headerGroup.headers.map((column, idx) => (
                // eslint-disable-next-line react/jsx-key
                <Th
                  {...column.getHeaderProps()}
                  p={1}
                  borderBottom="none"
                  color="primary.200"
                  textAlign={idx === 0 ? 'left' : 'center'}
                >
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell, idx) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Td
                      {...cell.getCellProps()}
                      p={1}
                      borderBottomColor="primary.500"
                      textAlign={idx === 0 ? 'left' : 'center'}
                      fontSize="sm"
                    >
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Box>
        <Text padding="4px 0 0 4px">Extras: {partnership.extraRuns}</Text>
      </Box>
    </Box>
  )
}
