import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useMatchIds } from '../../contexts/match.context'
import { getMatchTeamById } from '../../helpers/match'
import {
  CompetitionAgeGroupTypes,
  CompetitionGenderTypes,
  MatchClasses,
  MatchFormats,
  TossDecisionOptions,
} from '../../reference'
import type { Ball } from '../../types/ball'
import type { Match, MatchDls } from '../../types/match'
import { EntityLink } from '../entity-link'

type InfoSectionProps = {
  setModalAndBall?: (data: Ball | MatchDls | null) => void
  game: Match
}

export const InfoSection = ({ setModalAndBall, game }: InfoSectionProps) => {
  const { id } = useParams()
  const { matchIds, displayIdType } = useMatchIds()

  const [matchInfo, setMatchInfo] = useState(game)

  useEffect(() => {
    setMatchInfo(game)
  }, [game])

  return (
    <Stack spacing={3}>
      <Heading as="h3" size="md">
        Game information
      </Heading>
      <Box border="1px solid" borderColor="primary.600" borderRadius="6px" py={3} px={4}>
        <Stack spacing={1}>
          <Text textStyle="label">
            Toss Won By:
            <EntityLink
              entityType="teams"
              entityId={getMatchTeamById(matchInfo.wonTossMatchTeamId, game)?.teamId}
              idDisplay="inline"
            >
              <Text as="span" textStyle="boldLabel" ml={2}>
                {matchInfo.wonTossMatchTeamId && getMatchTeamById(matchInfo.wonTossMatchTeamId, game)?.name}
              </Text>
            </EntityLink>
          </Text>
          <Text textStyle="label">
            Toss Decision:
            <Text as="span" textStyle="boldLabel" ml={2}>
              {!isNil(matchInfo.tossDecisionId) ? TossDecisionOptions[matchInfo.tossDecisionId] : 'No decision'}
            </Text>
          </Text>
          <Text textStyle="label">
            Umpires:
            {matchInfo.matchOfficials?.map(official => (
              <EntityLink entityType="people" entityId={official.official.id} idDisplay="inline" key={official.id}>
                <Text as="span" textStyle="boldLabel" marginLeft="12px">
                  {official.cardNameF ||
                    official.official.person?.cardNameF ||
                    `${official.official.person?.firstName} ${official.official.person?.lastName}`}
                </Text>
              </EntityLink>
            ))}
          </Text>
        </Stack>
        {matchInfo.matchDls && setModalAndBall && (
          <Flex
            direction="row"
            alignItems="center"
            mt="15px"
            mb="10px"
            py="15px"
            borderTop="solid 1px"
            borderBottom="solid 1px"
            borderColor="primary.600"
          >
            <Text textStyle="label">DLS:</Text>
            <Button variant="secondary" ml={3} onClick={() => setModalAndBall(matchInfo.matchDls)}>
              View DLS JSON payload
            </Button>
          </Flex>
        )}
        <Text textStyle="label" py={1} mt="15px">
          Competition:
          <EntityLink
            entityType="competitions"
            entityId={matchInfo.competitionStage?.competition?.id}
            idDisplay="inline"
          >
            <Text as="span" textStyle="boldLabel" ml={2}>
              {matchInfo.competitionStage?.competition?.name || matchInfo.competitionPool?.name}
            </Text>
          </EntityLink>
        </Text>
        <Text textStyle="label" py={1}>
          Match Class:
          <Text as="span" textStyle="boldLabel" ml={2}>
            {MatchClasses[matchInfo.matchClassId ?? 0]}
          </Text>
        </Text>
        <Text textStyle="label" py={1}>
          Match Format:
          <Text as="span" textStyle="boldLabel" ml={2}>
            {MatchFormats[matchInfo.matchFormatId ?? 0]}
          </Text>
        </Text>
        {matchInfo.competitionStage?.competition?.competitionAgeGroupId != undefined && (
          <Text textStyle="label" py={1}>
            Competition Age Group:
            <Text as="span" textStyle="boldLabel" ml={2}>
              {CompetitionAgeGroupTypes[matchInfo.competitionStage?.competition?.competitionAgeGroupId]}
            </Text>
          </Text>
        )}
        {matchInfo.competitionStage?.competition?.competitionGenderId != undefined && (
          <Text textStyle="label" py={1}>
            Competition Gender Group:
            <Text as="span" textStyle="boldLabel" ml={2}>
              {CompetitionGenderTypes[matchInfo.competitionStage?.competition?.competitionGenderId]}
            </Text>
          </Text>
        )}
        <Text textStyle="label" py={1}>
          Venue:
          <EntityLink entityType="venues" entityId={matchInfo.venue?.id} idDisplay="inline">
            <Text as="span" textStyle="boldLabel" ml={2}>
              {matchInfo.venue?.fullName}
            </Text>
          </EntityLink>
        </Text>
        <Text textStyle="label" py={1}>
          Match ID:
          <EntityLink entityType="matches" entityId={id} idDisplay="none">
            <Text as="span" textStyle="boldLabel" ml={2}>
              {displayIdType === 'sds' ? matchIds.get(id || '') : id}
            </Text>
          </EntityLink>
        </Text>
      </Box>
    </Stack>
  )
}
