import {
  Button,
  Code,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Stack,
} from '@chakra-ui/react'
import type { CompetitionBase, GetCompetitionsResponse } from '@clsplus/api-types/api-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { InfiniteData, QueryClient } from '@tanstack/react-query'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { Link as RouterLink, useLoaderData, useSearchParams } from 'react-router-dom'

import { competitionsQuery } from '../../../api/get-competitions'
import { organisationsRefDataQuery } from '../../../api/get-organisations-ref-data'
import { teamsRefDataQuery } from '../../../api/get-teams-ref-data'
import { ListPageFilters } from '../../../components/list-page-filters'
import { formatShortDate } from '../../../helpers/datetime'
import type { ComboBoxOption } from '../../../ui/combo-box'
import { ComboBox } from '../../../ui/combo-box'
import { DatePicker } from '../../../ui/date-picker'
import { Input } from '../../../ui/input'
import { NonIdealState } from '../../../ui/non-ideal-state'
import { Table } from '../../../ui/table'

type CompetitionsFilters = {
  deleted: string
  endDate: Date | null
  id: string
  intId: string
  name: string
  organisationId: string
  startDate: Date | null
  teamIds: string[]
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const urlParams = Object.fromEntries(new URL(request.url).searchParams)
    const competitionsQueryOpts = competitionsQuery(urlParams)

    const [initialOrganisationsRefData, initialTeamsRefData, initialCompetitionsData] = await Promise.all([
      queryClient.ensureQueryData(organisationsRefDataQuery),
      queryClient.ensureQueryData(teamsRefDataQuery),
      queryClient.getQueryData<InfiniteData<GetCompetitionsResponse>>(competitionsQueryOpts.queryKey) ??
        (await queryClient.fetchInfiniteQuery(competitionsQueryOpts)),
    ])

    return {
      initialCompetitionsData,
      initialOrganisationsRefData,
      initialTeamsRefData,
    }
  }

export default function Competitions() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = Object.fromEntries(searchParams)
  const paramsCount = Object.keys(params).length

  const { initialCompetitionsData, initialOrganisationsRefData, initialTeamsRefData } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >

  const { data: competitions, ...competitionsQueryResult } = useInfiniteQuery({
    ...competitionsQuery(params),
    initialData: initialCompetitionsData,
  })

  const { data: organisationsRefData } = useQuery({
    ...organisationsRefDataQuery,
    initialData: initialOrganisationsRefData,
  })

  const { data: teamsRefData } = useQuery({
    ...teamsRefDataQuery,
    initialData: initialTeamsRefData,
  })

  const filtersFormDefaultValues = {
    deleted: params.deleted ?? '',
    endDate: params.endDate ? new Date(params.endDate) : null,
    id: params.id ?? '',
    intId: params.intId ?? '',
    name: params.name ?? '',
    organisationId: params.organisationId ?? '',
    startDate: params.startDate ? new Date(params.startDate) : null,
    teamIds: params.teamIds ? params.teamIds.split(',') : [],
  }

  const { control, register, handleSubmit, reset } = useForm<CompetitionsFilters>({
    values: filtersFormDefaultValues,
  })

  const handleDismissFilters = () => reset(filtersFormDefaultValues)

  const handleSubmitClick = (formData: CompetitionsFilters) => {
    const newSearchParams = Object.entries(formData).reduce<Record<string, string>>((acc, [key, val]) => {
      if (val instanceof Array) {
        return val.length ? { [key]: val.join(','), ...acc } : acc
      }

      if (val instanceof Date) {
        return { [key]: formatShortDate(val), ...acc }
      }

      return {
        ...(val && { [key]: val }),
        ...acc,
      }
    }, {})

    setSearchParams(newSearchParams)
  }

  const handleResetClick = () => {
    reset(filtersFormDefaultValues)
    setSearchParams({})
  }

  const rows = competitions?.pages.flatMap(page => [...page.data])

  const columns: ColumnDef<CompetitionBase>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => (
          <Link as={RouterLink} to={row.original.id}>
            {row.original.name}
          </Link>
        ),
      },
      {
        accessorKey: 'organisation',
        accessorFn: row => row.organisation?.name,
        header: 'Organisation',
      },
      {
        accessorKey: 'coverageLvl',
        accessorFn: row => row.coverageLevel?.name,
        header: 'Coverage',
      },
      {
        accessorKey: 'startDate',
        accessorFn: row => (row.startDate ? formatShortDate(new Date(row.startDate)) : ''),
        header: 'Start date',
      },
      {
        accessorKey: 'endDate',
        accessorFn: row => (row.endDate ? formatShortDate(new Date(row.endDate)) : ''),
        header: 'End date',
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'intId',
        accessorFn: row => row.integrationIds?.SDS,
        header: 'SDS ID',
      },
      {
        accessorKey: 'deleted',
        accessorFn: row => (row.isDeleted ? 'true' : 'false'),
        header: 'Deleted',
        cell: ({ row }) => (row.original.isDeleted ? 'Yes' : 'No'),
      },
    ],
    []
  )

  const organisationOptions = [
    { value: 'null', label: 'None selected' },
    ...organisationsRefData.map(organisation => ({
      value: organisation.id,
      label: organisation.name,
    })),
  ]

  return (
    <>
      <Heading as="h2" size="lg">
        Competitions
      </Heading>
      <Stack w="100%" spacing={8} my={6}>
        <ListPageFilters
          formId="competitions-filters"
          onDismiss={handleDismissFilters}
          onReset={handleResetClick}
          onSubmit={handleSubmit(handleSubmitClick)}
          title="Filter competitions"
        >
          <FormControl>
            <FormLabel>Competition ID</FormLabel>
            <Input {...register('id')} />
          </FormControl>
          <FormControl>
            <FormLabel>SDS ID</FormLabel>
            <Input {...register('intId')} />
          </FormControl>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input {...register('name')} />
          </FormControl>
          <FormControl>
            <FormLabel>Organisation</FormLabel>
            <Controller
              control={control}
              name="organisationId"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  isSingleSelect
                  options={organisationOptions}
                  onChange={onChange}
                  selectedOptions={organisationOptions.filter(option => option.value === value)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Teams</FormLabel>
            <Controller
              control={control}
              name="teamIds"
              render={({ field: { onChange, value } }) => (
                <ComboBox
                  maxSelectedItems={5}
                  options={teamsRefData.map(team => ({ value: team.id, label: team.name }))}
                  onChange={onChange}
                  selectedOptions={value
                    .map(teamId => {
                      const selectedTeam = teamsRefData.find(team => team.id === teamId)
                      if (selectedTeam) {
                        return { value: selectedTeam.id, label: selectedTeam.name }
                      }
                      return undefined
                    })
                    .filter((option): option is ComboBoxOption => !!option)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Start date</FormLabel>
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, value } }) => (
                <InputGroup zIndex={10}>
                  <Input as={DatePicker} selected={value} onChange={onChange} dateFormat="dd/MM/yyyy" />
                  <InputRightElement pointerEvents="none">
                    <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                  </InputRightElement>
                </InputGroup>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>End date</FormLabel>
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value } }) => (
                <InputGroup zIndex={10}>
                  <Input as={DatePicker} selected={value} onChange={onChange} dateFormat="dd/MM/yyyy" />
                  <InputRightElement pointerEvents="none">
                    <Icon as={FontAwesomeIcon} icon={['fad', 'calendar-days']} color="primary.400" />
                  </InputRightElement>
                </InputGroup>
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Deleted</FormLabel>
            <Select {...register('deleted')}>
              <option value="">-</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>
        </ListPageFilters>
        {competitionsQueryResult.isError ? (
          <NonIdealState
            title="Error fetching competitions"
            description="Here's what we know:"
            iconColor="red.600"
            icon={['fad', 'triangle-exclamation']}
          >
            <Code mt={4} px={6} py={4} borderRadius="6px" bg="primary.600">
              {competitionsQueryResult.error instanceof Error
                ? competitionsQueryResult.error.message
                : 'An unknown error occurred. Please contact a system administrator.'}
            </Code>
            <Button
              mt={4}
              variant="secondary"
              leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
              onClick={handleResetClick}
            >
              Reset filters
            </Button>
          </NonIdealState>
        ) : rows?.length ? (
          <Table columns={columns} data={rows} />
        ) : (
          <NonIdealState
            title="No competitions found"
            description={
              paramsCount > 0
                ? "We couldn't find any competitions matching your filters"
                : "We couldn't find any competitions"
            }
            iconColor="primary.400"
            icon={['fas', 'face-confused']}
          >
            {paramsCount > 0 && (
              <Button
                mt={4}
                variant="secondary"
                leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'xmark-large']} />}
                onClick={handleResetClick}
              >
                Reset filters
              </Button>
            )}
          </NonIdealState>
        )}
        {competitionsQueryResult.isSuccess && competitionsQueryResult.hasNextPage && (
          <Button
            mt={8}
            variant="primary"
            leftIcon={<Icon as={FontAwesomeIcon} icon={['fas', 'ellipsis']} />}
            isLoading={competitionsQueryResult.isFetchingNextPage}
            onClick={() => competitionsQueryResult.fetchNextPage()}
          >
            Load more
          </Button>
        )}
      </Stack>
    </>
  )
}
