import type { PlacementWithLogical } from '@chakra-ui/react'
import {
  Box,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useMatchIds } from '../../contexts/match.context'
import { CurrentAccessLevel, getNavGroupAccess } from '../../helpers/auth'

type EntityLinkProps = {
  children?: ReactNode
  entityType: 'people' | 'teams' | 'venues' | 'competitions' | 'matches'
  entityId: string | null | undefined
  idDisplay?: 'inline' | 'popover' | 'none'
  popoverPlacement?: PlacementWithLogical
}

export function EntityLink({
  children,
  entityType,
  entityId,
  idDisplay = 'none',
  popoverPlacement = 'bottom',
}: EntityLinkProps) {
  const toast = useToast()
  const { matchIds, displayIdType } = useMatchIds()
  const displayId = displayIdType === 'sds' ? matchIds.get(entityId || '') : entityId || ''

  const entityAccessLevel = getNavGroupAccess().get(`/${entityType}`)
  const canLink = entityAccessLevel !== undefined ? CurrentAccessLevel() >= entityAccessLevel : false

  const copyId = () => {
    if (displayId) {
      navigator.clipboard.writeText(displayId)
      toast({
        title: 'ID copied to clipboard',
        status: 'success',
        duration: 2000,
      })
    }
  }

  if (!entityId) {
    return <>{children}</>
  }

  const link = canLink ? (
    <Link as={RouterLink} to={`/${entityType}/${entityId}`} target="_blank">
      {children}
    </Link>
  ) : (
    children
  )

  if (idDisplay === 'popover') {
    return (
      <Box maxW="fit-content">
        <Popover trigger="hover" openDelay={750} placement={popoverPlacement}>
          <PopoverTrigger>{link}</PopoverTrigger>
          <PopoverContent width={'unset'}>
            <PopoverArrow />
            <PopoverBody>
              <Text fontSize={'sm'} onClick={copyId} cursor="pointer">
                {displayId}
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    )
  }

  return (
    <HStack as="span" display="inline">
      {link}
      {idDisplay === 'inline' && (
        <Text fontSize={'sm'} textStyle="label" as="span" ml={2} onClick={copyId} cursor="pointer">
          {displayId}
        </Text>
      )}
    </HStack>
  )
}
